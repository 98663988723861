import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import Cookies from "universal-cookie";
import { getTranslate, getValue, prepareFormData } from "../contexts/Utils";
import Api from '../contexts/Api';
import { useNotification } from "../components/UseNotification";
import Formfields from "../components/Formfields";
import SelectAutocomplete from "../components/SelectAutocomplete";

const Informations = ({ lang = 'fr' }) => {
  const { notification } = useNotification();
  const { uuid } = useParams();
  const edit = Boolean(uuid);
  const cookies = new Cookies();
  const userData = cookies.get('nudge_user');
  const role = userData.role;
  const [errors, setErrors] = useState({});
  const [data, setData] = useState({});
  const [allCategories, setAllCategories] = useState(false);
  const selectedCategories = useRef([]);

  let categories = [];
  if (window._DATA.categories) categories = window._DATA.categories.map(e => ({ id: e.category_id, name: e.name }));

  // 
  // ─── GET USER INFO ───────────────────────────────────────
  //
  useEffect(() => {
    const getUsersInfo = async () => {
      let fields = [
        'role',
        'type',
        'status',
        'name',
        'firstname',
        'email',
        'phone',
        'address',
        'cp',
        'city',
        'company',
        'siret',
        'description',
        'all_categories',
        'categories',
        'reactivity',
        'status_expert',
        'company_name',
        'address_pro',
        'city_pro',
        'cp_pro',
        'social_capital',
        'legal_status',
        'expert_tva'
      ];

      // get specific fields for admin
      if (role === 3 && edit) fields.push('note');

      let params = {
        query: { user_get: edit ? uuid : userData.user_id, fields },
        endpoint: '/users',
        method: 'GET'
      }
      const user = await Api(params);

      if (user.success) {
        const d = Object.values(user.data)[0];
        setData(d);
        setAllCategories(d.all_categories);
        if (d.categories) selectedCategories.current = categories.filter(e => d.categories.includes(e.id));
      }
    }

    getUsersInfo();
  }, [userData.user_id, uuid, edit, role])

  // 
  // ─── HANDLE SUBMIT ───────────────────────────────────────
  //
  const handleSubmit = async (e, id) => {
    e.preventDefault();

    const formData = prepareFormData({ formId: id });

    if (id === 'user-form') {
      if (formData.errors) return setErrors(formData.errors);

      // 
      // ─── SUBMIT PASSWORD ───────────────────────────────────────
      //
      const update_password = Boolean(formData.fields.password_old.length && formData.fields.password.length);
      if (update_password) {
        const passwordParams = {
          data: { fields: JSON.stringify(formData.fields), update_password: true, user_edit: edit ? uuid : userData.user_id },
          endpoint: '/users',
          method: 'PUT'
        }
        const passwordUpdate = await Api(passwordParams);
        notification({ variant: passwordUpdate.success ? 'success' : 'error', message: passwordUpdate.message })
      }

      // 
      // ─── SUBMIT OTHER INFOS ───────────────────────────────────────
      //
      delete formData.fields.password_old;
      delete formData.fields.password;
      formData.fields.categories = selectedCategories.current.map(e => e.id).join(',');

      const currentContent = data.description ? JSON.parse(data.description) : {};
      formData.fields.description = JSON.stringify({ ...currentContent, [lang]: formData.fields.description });

      if (formData.fields.email === data.email) delete formData.fields.email;
    }

    const infoParams = {
      data: { fields: JSON.stringify(formData.fields), user_edit: edit ? uuid : userData.user_id },
      endpoint: '/users',
      method: 'PUT'
    }
    const userUpdate = await Api(infoParams);

    notification({ variant: userUpdate.success ? 'success' : 'error', message: userUpdate.message })

    // update cookie with new categories
    if (userUpdate.success) {
      const newUserData = { ...userData, ...{ categories: formData.fields.categories, all_categories: formData.fields.all_categories } };
      cookies.set('nudge_user', newUserData, {
        expires: new Date(Date.now() + 604800000), // time until expiration
        secure: false, // true if using https
        path: '/' // if not set, will gen cookie for different pages
      })
    }
  }

  // 
  // ─── FORMFIELDS ───────────────────────────────────────
  //
  const formFields = [
    { component: "text", name: "firstname", label: getTranslate('firstname', lang), required: true },
    { component: "text", name: "name", label: getTranslate('name', lang), required: true },
    { type: "email", component: "text", name: "email", label: "Email", required: true },
    { type: 'tel', component: "text", name: "phone", label: getTranslate('phone', lang), required: true },
    { type: "password", component: "text", name: "password_old", label: getTranslate('current_password', lang) },
    { type: "password", component: "text", name: "password", label: getTranslate('new_password', lang) },
    { component: "text", name: "address", label: getTranslate('address', lang), required: true },
    { component: "text", name: "cp", label: getTranslate('postal_code', lang), required: true },
    { component: "text", name: "city", label: getTranslate('city', lang), required: true },
    { cond: { type: 2, status_expert: 1 }, component: "text", name: "company_name", label: getTranslate('company_name', lang), required: true },
    { cond: { type: 1 }, component: "text", name: "company_name", label: getTranslate('company_name', lang), required: true },
    { cond: { type: 1 }, component: "text", name: "siret", label: "Siret", required: true },
    { cond: { type: 2, status_expert: 1 }, component: "text", name: "siret", label: "Siret", required: true },
    { cond: { type: 2 }, component: "text", type: 'number', name: "reactivity", label: getTranslate('reactivity', lang), required: true },
    { cond: { type: 2, status_expert: 1 }, component: "text", name: "address_pro", label: getTranslate('address_pro', lang), required: true },
    { cond: { type: 2, status_expert: 1 }, component: "text", name: "city_pro", label: getTranslate('city_pro', lang), required: true },
    { cond: { type: 2, status_expert: 1 }, component: "text", name: "cp_pro", label: getTranslate('postal_code_pro', lang), required: true },
    { cond: { type: 2, status_expert: 1 }, component: "text", type: "number", name: "social_capital", label: getTranslate('social_capital', lang), required: true },
    { cond: { type: 2, status_expert: 1 }, component: 'checkbox', name: 'expert_tva', label: "TVA applicable", checked: Boolean(data.expert_tva) },

    { cond: { type: 1 }, component: "text", name: "address_pro", label: getTranslate('address_pro', lang), required: true },
    { cond: { type: 1 }, component: "text", name: "city_pro", label: getTranslate('city_pro', lang), required: true },
    { cond: { type: 1 }, component: "text", name: "cp_pro", label: getTranslate('postal_code_pro', lang), required: true },
    { cond: { type: 1 }, component: "text", type: "number", name: "social_capital", label: getTranslate('social_capital', lang), required: true },
    {
      component: "select",
      name: "legal_status",
      label: getTranslate('legal_status'),
      required: true,
      data: Object.values(window._DATA.types.legal_status).map(e => ({ id: e.value, name: e.label })),
      cond: { type: 2, status_expert: 1 },
    },
  ]

  // 
  // ─── RENDER ───────────────────────────────────────
  //
  return <S.Container edit={edit}>
    <S.Form id='user-form'>
      <h2>Informations</h2>
      {Boolean(Object.keys(data).length) && <>
        {formFields.map((e, i) => {
          if (!e.cond || ((!e.cond.type || e.cond.type === data.type) && (!e.cond.status_expert || e.cond.status_expert === data.status_expert)))
            return <Formfields key={i} field={{ ...e, size: 'calc(50% - 10px)', error: getValue(errors, [e.name, 'message']), default: e.default ? e.default : e.component === 'select' ? data[e.name] || 1 : data[e.name] }} />;
          return null;
        })}
        <div className="categories">
          <Formfields field={{ component: 'switch', label: 'Voir toutes les catégories', name: 'all_categories', handleChange: () => setAllCategories(prev => !prev), default: allCategories }} />
          {!Boolean(allCategories) && <SelectAutocomplete width='100%' defaultValue={data.categories} ref={selectedCategories} array={categories} />}
        </div>
        <Formfields field={{ default: data.description && Boolean(Object.keys(data.description).length) && JSON.parse(data.description)[lang], component: "text", multiline: true, name: "description", label: 'Description', required: true, shrink: true, size: 'calc(50% - 10px)', error: getValue(errors, ['description', 'message']) }} />
        <S.ButtonContainer>
          <S.Button onClick={(e) => handleSubmit(e, 'user-form')}>{getTranslate(edit ? 'edit' : 'save', lang)}</S.Button>
        </S.ButtonContainer>
      </>}
    </S.Form>
    {role === 3 && edit && <S.Form id='admin-form'>
      <h2>INFORMATIONS ADMIN</h2>
      <Formfields field={{ size: 'calc(50% - 10px)', multiline: true, component: "text", name: "note", label: 'Note', required: false, default: data.note }} />
      <S.ButtonContainer>
        <S.Button onClick={(e) => handleSubmit(e, 'admin-form')}>{getTranslate(edit ? 'edit' : 'save', lang)}</S.Button>
      </S.ButtonContainer>
    </S.Form>}
  </S.Container>
}

export default Informations;

// 
// ─── STYLE DEFINITION ───────────────────────────────────────
//
const S = {}
S.Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  box-sizing: border-box;
  background: ${({ theme }) => theme.card};
  border-radius: ${({ edit }) => edit ? '10px' : '0px'};
  gap: 70px;

  & h2 {
    color: ${({ theme }) => theme.title} !important;
    font-size: 21px;
    text-transform: uppercase;
    width: 100%;
    margin: 0;
    margin-bottom: 20px;
  }
`

S.Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;

  & .formfield_container {
    margin-bottom: 10px;
  }

  & *:not(.Mui-error, button) {
    color: ${({ theme }) => theme.text} !important;
    border-color: ${({ theme }) => theme.text} !important;
  }
  & label.Mui-error {
    color: #d32f2f !important;
  }
  & div.Mui-error > input, div.Mui-error > textarea {
    border-color: #d32f2f !important;
  }
  .categories {
    width: calc(50% - 10px);

    .formfield_container {
      margin-bottom: 20px;
    }
  }

  @media(max-width: 780px) {
    .select-autocomplete {
      width: 100%;
    }
  }
`

S.ButtonContainer = styled.div`
width: 100%;
margin-top: 20px;
`

S.Button = styled.button`
  background: ${({ theme }) => theme.linear};
  border: none;
  color: #fff !important;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  padding: 0 25px;
  height: 35px;
  min-height: 35px;
  box-sizing: border-box;
  border-radius: 5px;
  width: fit-content;
  cursor: pointer;
`