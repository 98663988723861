import { useState } from "react";
import styled from "styled-components";
import { getTranslate } from "../contexts/Utils";
import Api from "../contexts/Api";
import { useNotification } from "./UseNotification";

const ModalValidatePrice = (props) => {
  const { data = {}, setModalData, lang, setMissions } = props;
  const { notification } = useNotification();
  const [loading, setLoading] = useState(false);

  // 
  // ─── HANDLE SUBMIT PRICE HOURLY ───────────────────────────────────────
  //
  const handleSubmit = async (e, accept) => {
    e.preventDefault();

    // loading is to prevent from multiple submit onClick
    if (loading) return;
    setLoading(true);

    let queryData = { fields: { status: accept ? 4 : 2 }, mission_id: data.id };
    if (!accept) queryData.fields.price = null;

    let params = {
      data: queryData,
      endpoint: '/missions',
      method: 'PUT'
    };

    const query = await Api(params);

    const message = getTranslate(accept ? 'price_accepted' : 'price_refused', lang);
    notification({ variant: query.success ? 'success' : 'error', message });

    if (query.success) {
      // close modal if success
      setModalData({});
      setLoading(false);

      // update missions list
      setMissions(prev => {
        let obj = { ...prev };
        obj[data.id].status = accept ? 4 : 2;
        if (!accept) obj[data.id].price = null;
        return obj;
      })
    }
  }

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return Boolean(Object.keys(data).length) && <S.Container id="form">
    <h3>{getTranslate('validate_price', lang)}</h3>
    <p>{getTranslate('proposed_price_commission', lang)} : {data.price}€ HT</p>
    <S.ButtonsContainer>
      <S.Button error={true} onClick={(e) => handleSubmit(e, false)}>{getTranslate('refuse', lang)}</S.Button>
      <S.Button onClick={(e) => handleSubmit(e, true)}>{getTranslate('validate', lang)}</S.Button>
    </S.ButtonsContainer>
  </S.Container>
}

export default ModalValidatePrice;

// 
// ─── STYLE DEFINITION ───────────────────────────────────────
//
const S = {}
S.Container = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  background: ${({ theme }) => theme.card};
  padding: 20px;
  z-index: 10;
  min-width: 350px;

  & h3 {
    color: ${({ theme }) => theme.text};
    margin: 0;
    margin-bottom: 20px;
  }

  @media (max-width: 520px) {
    min-width: unset;
    width: 80%;
  }
`

S.ButtonsContainer = styled.div`
  display: flex;
  gap: 20px;
`

S.Button = styled.button`
  background: ${({ theme, error }) => error ? theme.red : theme.linear};
  border: none;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  padding: 0 35px;
  height: 35px;
  min-height: 35px;
  box-sizing: border-box;
  border-radius: 5px;
  width: fit-content;
  margin-top: 30px;
  cursor: pointer;
`