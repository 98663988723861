import styled from "styled-components";

const CGV = () => {
  return <S.Container>
    <h1>CONDITIONS GENERALES DE VENTE – INTERNET</h1>

    <span>En vigueur au 01/01/2023</span>

    <h2>ARTICLE 1 - Champ d'application</h2>

    <p>Les présentes Conditions Générales de Vente (dites « CGV ») s'appliquent, sans restriction ni réserve, à tout achat des services suivants :</p>
    <p>Mise en relation, via une plateforme internet, de tous professionnels avec des experts dans le domaine de l'industrie en vue de l'obtention de conseils techniques et de la réalisation de prestations techniques.</p>

    <br />

    <p>Tels que proposés par le Prestataire aux clients professionnels/industriels (« Les Clients ou le Client ») sur le site <a href="https://www.nudgetech.fr">https://www.nudgetech.fr</a></p>
    <p>Les caractéristiques principales des Services sont présentées sur le site internet : <a href="https://www.nudgetech.fr">https://www.nudgetech.fr</a></p>
    <p>Le Client est tenu d'en prendre connaissance avant toute passation de commande. Le choix et l'achat d'un Service est de la seule responsabilité du Client.</p>

    <br />

    <p>Ces CGV sont accessibles à tout moment sur le site <a href="https://www.nudgetech.fr">https://www.nudgetech.fr</a> et prévaudront sur tout autre document.</p>
    <p>Le Client déclare avoir pris connaissance des présentes CGV et les avoir acceptées en cochant la case prévue à cet effet avant la mise en œuvre de la procédure de commande en ligne du site <a href="https://www.nudgetech.fr">https://www.nudgetech.fr</a></p>
    <p>Sauf preuve contraire, les données enregistrées dans le système informatique du Prestataire constituent la preuve de l'ensemble des transactions conclues avec le Client.</p>

    <br />

    <p style={{ textDecoration: 'underline' }}>Les coordonnées du Prestataire sont les suivantes :</p>
    <p><strong>NUDGE TECH (EURL)</strong></p>
    <p>Capital social de 4000 euros</p>
    <p>Immatriculé au RCS de COLMAR, sous le numéro 921082574</p>
    <p>23A rue de FESSENHEIM, 68740 BALGAU</p>
    <p>mail : regis.schmidt@nudgetech.fr / regis.schmidt@wanadoo.fr</p>
    <p>téléphone : 0783310746</p>

    <br />

    <p>Si des droits de douane ou autres taxes locales ou droits d'importation ou taxes d'état sont susceptibles d'être exigibles, ils seront à la charge du Client et relèveront de sa seule responsabilité.</p>

    <h2>ARTICLE 2 - Prix</h2>

    <p>Les Services sont fournis aux tarifs en vigueur figurant sur le site <a href="https://www.nudgetech.fr">https://www.nudgetech.fr</a>, lors de l'enregistrement de la commande par le Prestataire.</p>
    <p>Les prix sont exprimés en Euros, HT et TTC.</p>
    <p>Les tarifs tiennent compte d'éventuelles réductions qui seraient consenties par le Prestataire sur le site <a href="https://www.nudgetech.fr">https://www.nudgetech.fr</a></p>
    <p>Ces tarifs sont fermes et non révisables pendant leur période de validité mais le Prestataire se réserve le droit, hors période de validité, d’en modifier les prix à tout moment.</p>
    <p>Le paiement demandé au Client correspond au montant total de l'achat.</p>
    <p>Une facture est établie par le Prestataire et enregistrée au niveau de la base de données du site.</p>

    <h2>ARTICLE 3 – Commandes</h2>

    <p>Il appartient au Client de sélectionner sur le site <a href="https://www.nudgetech.fr">https://www.nudgetech.fr</a> les Services qu'il désire commander, selon les modalités suivantes :</p>
    <p>Après une inscription sur la plateforme Nudge Tech et le paiement de l’abonnement (quelques informations générales classiques, un TIP SEPA…), les clients peuvent y déposer leurs requêtes techniques. Ce dépôt de requête se fait sous la forme d’un bref descriptif.</p>
    <p>Une fois ce dépôt validé, la requête est visible par l’ensemble des experts, membres de la Communauté Nudge Tech.</p>
    <p>Lorsqu'un expert est capable de répondre à une requête technique, celui-ci la «bloque » dans le système.</p>
    <p>Dans la foulée, l'expert établit un contact direct avec le client pour de plus amples explications techniques et pour convenir du montant de la transaction, du délai de réponse et du type de livrable souhaité.</p>
    <p>A noter que le livrable pourra prendre n’importe quelle forme : mail, vidéo, PowerPoint, fichier Excel,…</p>
    <p>Tout dépendra de l’exploitation qui en sera faite en interne de l’entreprise « Client ».</p>
    <p>Durant le traitement de la requête, le système se voulant des plus simples, les échanges se feront directement entre les deux protagonistes via les outils classiques de communication (mails, vidéoconférence, téléphone,…).</p>
    <p>Lorsque l’expert aura transmis son livrable, le client se verra immédiatement débité du montant de la prestation via un prélèvement SEPA et, en parallèle, l'expert sera payé de sa prestation via un virement SEPA. Au passage, la plateforme internet aura prélevé sa commission..</p>
    <p>La vente ne sera considérée comme valide qu’après paiement intégral du prix. Il appartient au Client de vérifier l'exactitude de la commande et de signaler immédiatement toute erreur.</p>
    <p>Toute commande passée sur le site <a href="https://www.nudgetech.fr">https://www.nudgetech.fr</a> constitue la formation d'un contrat conclu à distance entre le Client et le Prestataire.</p>
    <p>Le Prestataire se réserve le droit d'annuler ou de refuser toute commande d'un Client avec lequel il existerait un litige relatif au paiement d'une commande antérieure.</p>
    <p>Le Client pourra suivre l'évolution de sa commande sur le site.</p>

    <h2>ARTICLE 4 - Conditions de paiement</h2>

    <p>Le prix est payé par voie de paiement sécurisé, selon les modalités suivantes :</p>
    <ul>
      <li>Paiement par virement bancaire sur le compte bancaire de NUDGE TECH (dont les coordonnées sont communiquées au Client lors de la passation de la commande)</li>
      <li>Le prix est payable comptant, en totalité au jour de la fourniture des Services, intervenant selon les conditions définies à l'article « Fourniture des Prestations » et comme indiqué sur la facture Client.</li>
      <li>Les données de paiement sont échangées en mode crypté grâce au protocole défini par le prestataire de paiement agréé intervenant pour les transactions bancaires réalisées sur le site <a href="https://www.nudgetech.fr">https://www.nudgetech.fr</a></li>
      <li>Les paiements effectués par le Client ne seront considérés comme définitifs qu'après encaissement effectif des sommes dues, par le Prestataire.</li>
      <li>Le Prestataire ne sera pas tenu de procéder à la fourniture des Services commandés par le Client si celui-ci ne lui en paye pas le prix en totalité dans les conditions ci-dessus indiquées.</li>
    </ul>

    <h2>ARTICLE 5 - Fourniture des Prestations</h2>

    <p>Les Services commandés par le Client seront fournis selon les modalités suivantes :</p>

    <br />

    <p>Mise en relation, via une plateforme internet, de tous professionnels avec des experts dans le domaine de l'industrie en vue de l'obtention de conseils techniques et de la réalisation de prestations techniques.</p>
    <p>(Note : Se référer également à la rubrique du site internet intitulée « Comment ça fonctionne ? »).</p>

    <br />

    <p>Lesdits Services seront fournis dans un délai maximum de 15 jours à compter de la validation définitive de la commande du Client, dans les conditions prévues aux présentes CGV à l'adresse indiquée par le Client lors de sa commande sur le site <a href="https://www.nudgetech.fr">https://www.nudgetech.fr</a></p>
    <p>Le Prestataire s'engage à faire ses meilleurs efforts pour fournir les Services commandés par le Client, dans le cadre d'une obligation de moyen et dans les délais ci-dessus précisés.</p>

    <br />

    <p>Si les Services commandés n'ont pas été fournis dans un délai de 10 jours après la date indicative de fourniture, pour toute autre cause que la force majeure ou le fait du Client, la vente des Services pourra être résolue à la demande écrite du Client dans les conditions prévues aux articles L 216-2, L 216-3 et L241-4 du Code de la consommation.</p>
    <p>Les sommes versées par le Client lui seront alors restituées au plus tard dans les quatorze jours qui suivent la date de dénonciation du contrat, à l'exclusion de toute indemnisation ou retenue.</p>
    <p>En cas de demande particulière du Client concernant les conditions de fourniture des Services, dûment acceptées par écrit par le Prestataire, les coûts y étant liés feront l'objet d'une facturation spécifique complémentaire ultérieure.</p>

    <br />

    <p>A défaut de réserves ou réclamations expressément émises par le Client lors de la réception des Services, ceux-ci seront réputés conformes à la commande, en quantité et qualité.</p>
    <p>Le Client disposera d'un délai de 10 jours à compter de la fourniture des Services pour émettre des réclamations par courrier et mail., avec tous les justificatifs y afférents, auprès du Prestataire.</p>
    <p>Aucune réclamation ne pourra être valablement acceptée en cas de non respect de ces formalités et délais par le Client.</p>
    <p>Le Prestataire remboursera ou rectifiera dans les plus brefs délais et à ses frais les Services dont le défaut de conformité aura été dûment prouvé par le Client.</p>

    <h2>ARTICLE 6 - Droit de rétractation</h2>

    <p>Un éventuel droit de rétractation fera systématiquement l’objet d’une discussion tripartite entre le client, l’expert et le gérant de la société NUDGE TECH.</p>

    <h2>ARTICLE 7 - Responsabilité du Prestataire - Garanties</h2>

    <p>Le Prestataire garantit, conformément aux dispositions légales et sans paiement complémentaire, le Client, contre tout défaut de conformité ou vice caché, provenant d'un défaut de conception ou de réalisation des Services commandés.</p>

    <br />

    <p>Afin de faire valoir ses droits, le Client devra informer le Prestataire, par écrit (mail ou courrier), de l'existence des vices ou défauts de conformité.</p>
    <p>Le Prestataire remboursera ou rectifiera ou fera rectifier (dans la mesure du possible) les services jugés défectueux dans les meilleurs délais et conformément aux normes bancaires SEPA en vigueur.</p>
    <p>En cas de remboursement, celui-ci se fera par virement bancaire.</p>
    <p>La garantie du Prestataire est limitée au remboursement des Services effectivement payés par le Client.</p>
    <p>Le Prestataire ne pourra être considéré comme responsable ni défaillant pour tout retard ou inexécution consécutif à la survenance d'un cas de force majeure habituellement reconnu par la jurisprudence française.</p>
    <p>Les Services fournis sont conformes à la réglementation en vigueur en France. La responsabilité du Prestataire ne saurait être engagée en cas de non respect de la législation du pays dans lequel les Services sont fournis, qu'il appartient au Client, qui est seul responsable du choix des Services demandés, de vérifier.</p>

    <h2>ARTICLE 8 - Données personnelles</h2>

    <p>Le Client est informé que la collecte de ses données à caractère personnel est nécessaire à la vente des Services et leur réalisation et leur délivrance, ainsi qu'à leur transmission à des tiers intervenant dans la réalisation des Services. Ces données à caractère personnel sont récoltées uniquement pour l’exécution du contrat de prestations de services.</p>

    <h3>8.1 Collecte des données à caractère personnel</h3>

    <p>Les données à caractère personnel qui sont collectées sur le site <a href="https://www.nudgetech.fr">https://www.nudgetech.fr</a> sont les suivantes :</p>

    <p><strong>Ouverture de compte</strong></p>
    <p>Lors de la création du compte Client / utilisateur :</p>
    <p>Noms, prénoms, adresse postale, numéro de téléphone et adresse e-mail.</p>

    <p><strong>Paiement</strong></p>
    <p>Dans le cadre du paiement des Prestations proposés sur le site <a href="https://www.nudgetech.fr">https://www.nudgetech.fr</a>, celui-ci enregistre des données financières relatives au compte bancaire du Client / utilisateur.</p>

    <h3>8.2 Destinataires des données à caractère personnel</h3>

    <p>Les données à caractère personnel sont utilisées par le Prestataire et ses co-contractants pour l’exécution du contrat et pour assurer l’efficacité de la prestation de services, sa réalisation et sa délivrance.</p>

    <p>La ou les catégorie(s) de co-contractant(s) est (sont) :</p>
    <ul>
      <li>Les prestataires établissements de paiement</li>
      <li>Le responsable de traitement des données est le Prestataire, au sens de la loi Informatique et libertés et à compter du 25 mai 2018 du Règlement 2016/679 sur la protection des données à caractère personnel.</li>
    </ul>

    <h3>8.4 limitation du traitement</h3>

    <p>Sauf si le Client exprime son accord exprès, ses données à caractère personnelles ne sont pas utilisées à des fins publicitaires ou marketing.</p>

    <h3>8.5 Durée de conservation des données</h3>

    <p>Le Prestataire conservera les données ainsi recueillies pendant un délai de 5 ans, couvrant le temps de la prescription de la responsabilité civile contractuelle applicable.</p>

    <h3>8.6 Sécurité et confidentialité</h3>

    <p>Le Prestataire met en œuvre des mesures organisationnelles, techniques, logicielles et physiques en matière de sécurité du numérique pour protéger les données personnelles contre les altérations, destructions et accès non autorisés.</p>
    <p>Toutefois il est à signaler qu’Internet n’est pas un environnement complètement sécurisé et le Prestataire ne peut garantir la sécurité de la transmission ou du stockage des informations sur Internet.</p>

    <h3>8.7 Mise en œuvre des droits des Clients et utilisateurs</h3>

    <p>En application de la règlementation applicable aux données à caractère personnel, les Clients et utilisateurs du site <a href="https://www.nudgetech.fr">https://www.nudgetech.fr</a> disposent des droits suivants :</p>
    <ul>
      <li>Ils peuvent mettre à jour ou supprimer les données, qui les concernent, en en faisant la demande auprès de l'administrateur de la plateforme internet.</li>
      <li>Ils peuvent supprimer leur compte en écrivant à l’adresse électronique indiqué à l’article 9.3 « Responsable de traitement »</li>
      <li>Ils peuvent exercer leur droit d’accès pour connaître les données personnelles les concernant en écrivant à l’adresse indiqué à l’article 9.3 « Responsable de traitement »</li>
      <li>Si les données à caractère personnel détenues par le Prestataire sont inexactes, ils peuvent demander la mise à jour des informations des informations en écrivant à l’adresse indiqué à l’article 9.3 « Responsable de traitement »</li>
      <li>Ils peuvent demander la suppression de leurs données à caractère personnel, conformément aux lois applicables en matière de protection des données en écrivant à l’adresse indiqué à l’article 9.3 « Responsable de traitement »</li>
      <li>Ils peuvent également solliciter la portabilité des données détenues par le Prestataire vers un autre prestataire</li>
      <li>Enfin, ils peuvent s’opposer au traitement de leurs données par le Prestataire</li>
    </ul>

    <p>Ces droits, dès lors qu’ils ne s’opposent pas à la finalité du traitement, peuvent être exercés en adressant une demande par courrier ou par E-mail au Responsable de traitement dont les coordonnées sont indiquées ci-dessus.</p>
    <p>Le responsable de traitement doit apporter une réponse dans un délai maximum d’un mois.</p>
    <p>En cas de refus de faire droit à la demande du Client, celui-ci doit être motivé.</p>
    <p>Le Client est informé qu’en cas de refus, il peut introduire une réclamation auprès de la CNIL (3 place de Fontenoy, 75007 PARIS) ou saisir une autorité judiciaire.</p>
    <p>Le Client peut être invité à cocher une case au titre de laquelle il accepte de recevoir des mails à caractère informatifs et publicitaires de la part du Prestataire. Il aura toujours la possibilité de retirer son accord à tout moment en contactant le Prestataire (coordonnées ci-dessus).</p>

    <h2>ARTICLE 9 - Propriété intellectuelle</h2>

    <p>Le contenu du site <a href="https://www.nudgetech.fr">https://www.nudgetech.fr</a> est la propriété du Vendeur et de ses partenaires et est protégé par les lois françaises et internationales relatives à la propriété intellectuelle.</p>
    <p>Toute reproduction totale ou partielle de ce contenu est strictement interdite et est susceptible de constituer un délit de contrefaçon.</p>

    <h2>ARTICLE 10 - Droit applicable - Langue</h2>

    <p>Les présentes CGV et les opérations qui en découlent sont régies et soumises au droit français.</p>
    <p>Les présentes CGV sont rédigées en langue française.</p>
    <p>Dans le cas où elles seraient traduites en une ou plusieurs langues étrangères, seul le texte français ferait foi en cas de litige.</p>

    <h2>ARTICLE 11 - Litiges</h2>

    <p>Pour toute réclamation, merci de contacter le service clientèle à l’adresse postale ou mail du Prestataire indiquée à l’ARTICLE 1 des présentes CGV.</p>
    <p>Le Client est informé qu'il peut en tout état de cause recourir à une médiation conventionnelle, auprès des instances de médiation sectorielles existantes ou à tout mode alternatif de règlement des différends (conciliation, par exemple) en cas de contestation.</p>

    <br />

    <p>Le Client est également informé qu’il peut, également recourir à la plateforme de Règlement en Ligne des Litige (RLL) :https://webgate.ec.europa.eu/odr/main/index.cfm?event=main.home.show</p>
    <p>Tous les litiges auxquels les opérations d'achat et de vente conclues en application des présentes CGV et qui n’auraient pas fait l’objet d’un règlement amiable entre le vendeur ou par médiation, seront soumis aux tribunaux compétents dans les conditions de droit commun.</p>
  </S.Container>
}

export default CGV;

const S = {}
S.Container = styled.div`
  background: ${({ theme }) => theme.card};
  color: ${({ theme }) => theme.text};
  box-sizing: border-box;
  border-radius: 5px;
  font-weight: lighter;
  font-size: 15px;
  padding: 30px;

  & h1 {
    margin-top: 0;
  }

  & a {
    color: ${({ theme }) => theme.title};
  }

  & p {
    margin: 0
  }
`