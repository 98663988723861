import styled from "styled-components";

const SubscriptionExplanation = (props) => {

  const CheckSvg = () => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" /></svg>

  return <S.Container is_active={props.is_active} onClick={() => props.handleChange(props.type)}>
    <h2>Abonnement mensuel</h2>
    {props.type === 'monthly' ? <span>55 € HT</span> :
      <div>
        <span>600 € HT</span>
        <span>au lieu de 660 € HT</span>
      </div>}
    <p><CheckSvg /> Nombre de requêtes illimitées</p>
    <p><CheckSvg /> Un conseiller NUDGE dédié</p>
    <p><CheckSvg /> Mise en relation avec la communauté d'experts NUDGE</p>
  </S.Container>
}

export default SubscriptionExplanation;

const S = {}
S.Container = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.background};
  border-radius: 5px;
  padding: 20px 25px;
  gap: 10px;
  max-width: 278px;
  border:2px solid ${({ is_active }) => is_active ? ({ theme }) => theme.title : 'transparent'};

  & h2 {
    margin-bottom: 0 !important;
  }
  & > span {
    font-size: 30px;
    margin-bottom: 15px;
  }
  & p {
    font-weight: lighter;
    margin: 0;
    display: flex;
    gap: 15px;

    & svg {
      width: 20px;
      min-width: 20px;
      fill: ${({ theme }) => theme.title};
    }
  }
  & div {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;

    & span:first-child {
      font-size: 30px;
    }
    & span:last-child {
      font-size: 14px;
      font-weight: lighter;
    }
  }
`