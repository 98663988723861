import { useEffect, useState } from "react";
import styled from "styled-components";
import Cookies from "universal-cookie";
import { getTranslate } from "../contexts/Utils";
import Api from '../contexts/Api';
import LineChart from "../components/LineChart";

const Analytics = ({ lang }) => {
  const cookies = new Cookies();
  const userData = cookies.get('nudge_user');
  const role = userData.role;
  const [data, setData] = useState({});

  // 
  // ─── GET STATS ───────────────────────────────────────
  //
  useEffect(() => {
    const getData = async () => {
      const params = {
        endpoint: '/data/analytics',
        method: 'GET'
      }
      const queryResult = await Api(params);

      if (queryResult.success && queryResult.data) {
        let formatedData = {};

        Object.entries(queryResult.data).map(([key, value]) => {
          let dbKey = 'count';
          if (key === 'prices') dbKey = 'ca'
          // parse to obj
          let obj = {}
          value.forEach(e => obj[e.month] = e)
          // right array format for lineChart
          let arr = Array.from(Array(12).keys());
          arr = arr.map(e => {
            if (obj.hasOwnProperty(e + 1)) return obj[e + 1][dbKey];
            return null;
          })
          formatedData[key] = arr;
        })
        setData(formatedData)
      }
    }
    getData()
  }, [userData.user_id])

  // 
  // ─── RENDER ───────────────────────────────────────
  //
  return <S.Container>
    <h2>Analytics</h2>
    <S.Box>
      <span>{getTranslate(role === 1 ? 'turnover' : 'amount_of_expenses', lang)} (€)</span>
      <LineChart data={data.prices} />
    </S.Box>
    <S.Box>
      <span>{getTranslate('total_missions', lang)}</span>
      <LineChart data={data.number} borderColor='#0cb7f0' backgroundColor="rgba(29, 74, 89,0.2)" />
    </S.Box>
  </S.Container>
}

export default Analytics;

// 
// ─── STYLE DEFINITION ───────────────────────────────────────
//
const S = {}
S.Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px 0px;
  background: ${({ theme }) => theme.card};
  padding: 30px;
  border-radius: 10px;
  max-width: 1100px;

  & h2 {
    color: ${({ theme }) => theme.text} !important;
    font-size: 24px;
    text-transform: uppercase;
    margin: 0;
    margin-bottom: 20px;
    width: 100%;
  }
`
S.Box = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 300px;
  gap: 10px;
  width: calc(50% - 15px);

  @media(max-width: 1000px) {
    &:nth-child(2) {
      margin-bottom: 20px;
    }
    width: 100%;
  }

  & > span {
    color: ${({ theme }) => theme.title};
    text-transform: uppercase;
    font-size: 15px;
    font-weight: bold;
  }
`