import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { getTranslate } from "../contexts/Utils";
import Api from '../contexts/Api';
import { useNotification } from "../components/UseNotification";

const Categories = ({ lang }) => {
  const { notification } = useNotification();
  const inputCreate = useRef();
  const [searchValue, setSearchValue] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [currentCategory, setCurrentCategory] = useState({});
  const [categories, setCategories] = useState({});
  const [focusActive, setFocusActive] = useState(false);

  // convert array to object
  useEffect(() => {
    if (window._DATA.categories) {
      let catObj = {};
      window._DATA.categories.forEach(e => catObj[e.category_id] = e);
      setCategories(catObj);
    }
  }, [])

  // 
  // ─── HANDLE CATEGORY CLICK ON SELECT ───────────────────────────────────────
  //
  const handleCatClick = (e, id) => {
    setFocusActive(false);

    setSearchValue(categories[id].name);
    setInputValue(categories[id].name)
    setCurrentCategory(id);
  }

  // 
  // ─── HANDLE CATEGORY SUBMIT ───────────────────────────────────────
  //
  const handleSubmitCat = async (edit) => {
    if ((edit && !inputValue) || (!edit && !inputCreate.current.value)) return;

    const data = {
      fields: { name: edit ? inputValue : inputCreate.current.value },
      category_id: currentCategory
    }

    const params = {
      endpoint: '/categories',
      method: edit ? 'PUT' : 'POST',
      data
    }

    const query = await Api(params);
    notification({ variant: query.success ? 'success' : 'error', message: query.message })

    // update current categories
    if (query.success) {
      const obj = { ...categories };

      if (edit) obj[currentCategory] = { category_id: currentCategory, name: inputValue };
      else obj[query.category_id] = { category_id: query.category_id, name: inputCreate.current.value };

      setCategories(obj);
      window._DATA.categories = Object.values(obj);
    }
  }

  // 
  // ─── HANDLE SEARCH FOCUS ───────────────────────────────────────
  //
  const handleFocus = (val) => {
    if (!val) {
      setTimeout(() => {
        setFocusActive(val);
      }, 150);
      return
    }
    setFocusActive(val);
  }

  // 
  // ─── HANDLE SEARCH CHANGE ───────────────────────────────────────
  //
  const handleSearchChange = (e) => {
    if (currentCategory) setCurrentCategory({})
    setSearchValue(e.target.value);
  }

  // 
  // ─── HANDLE DELETE ───────────────────────────────────────
  //
  const handleDelete = async () => {
    const params = {
      endpoint: '/categories',
      method: 'DELETE',
      data: { category_id: currentCategory }
    }

    const query = await Api(params);
    notification({ variant: query.success ? 'success' : 'error', message: query.message })

    // update current categories
    if (query.success) {
      const obj = { ...categories };
      delete obj[currentCategory];

      setCategories(obj);
      window._DATA.categories = Object.values(obj);

      setInputValue("");
      setSearchValue("");
      setCurrentCategory("");
    }
  }

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <S.Container>
    <h2>{getTranslate('categories_management', lang)}</h2>
    <S.CreateContainer>
      <h3>{getTranslate('create_category', lang)}</h3>
      <S.Input type="text" ref={inputCreate} />
      <S.Button onClick={() => handleSubmitCat(false)}>{getTranslate('create', lang)}</S.Button>
    </S.CreateContainer>
    <S.SearchContainer>
      <h3>{getTranslate('modify_category', lang)}</h3>
      <S.InputSearch type="search" value={searchValue} placeholder={getTranslate('search', lang)} onFocus={() => handleFocus(true)} onBlur={() => handleFocus(false)} onChange={handleSearchChange} />
      {focusActive &&
        <S.List>
          {Object.values(categories).map(e => e.name.toLowerCase().includes(searchValue.toLowerCase()) && <div><span onClick={(event) => handleCatClick(event, e.category_id)} key={e.category_id}>{e.name}</span></div>)}
        </S.List>}
      {Boolean(Object.keys(currentCategory).length) && <S.InputContainer>
        <S.Input type="text" value={inputValue} onChange={(e) => setInputValue(e.target.value)} />
        <div>
          <S.Button color="error" onClick={handleDelete}>{getTranslate('delete', lang)}</S.Button>
          <S.Button onClick={() => handleSubmitCat(true)}>{getTranslate('edit', lang)}</S.Button>
        </div>
      </S.InputContainer>}
    </S.SearchContainer>
  </S.Container>
}

export default Categories;

// 
// ─── STYLE DEFINITION ───────────────────────────────────────
//
const S = {}
S.Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: ${({ theme }) => theme.card};
  border-radius: 10px;
  padding: 20px;
  box-sizing: border-box;

  & > h2 {
    color: ${({ theme }) => theme.text};
    text-transform: uppercase;
    font-size: 24px;
    margin: 0;
    margin-bottom: 10px;
    width: 100%;
  }
  & h3 {
    color: ${({ theme }) => theme.title};
    text-transform: uppercase;
    font-size: 18px;
    margin: 0;
    margin-bottom: 8px;
    width: 100%;
  }
`

S.CreateContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: fit-content;
  gap: 10px;
`

S.SearchContainer = styled.div`
  display: flex;
  position: relative;
  width: fit-content;
  flex-wrap: wrap;
  margin-top: 20px;
  gap: 10px;
`

S.InputSearch = styled.input`
  height: 35px;
  color: ${({ theme }) => theme.black};
  border: none;
  background: ${({ theme }) => theme.inputBg};
  width: 350px;
  border-radius: 5px;
  padding: 0 15px;
  box-sizing: border-box;
  font-size: 15px;
  font-family: 'Poppins';
  transition: .3s;

  &:focus {
    outline: none;
  }
`

S.List = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 85px;
  border-radius: 5px;
  width: 350px;
  box-sizing: border-box;
  background: ${({ theme }) => theme.inputBg};
  max-height: 180px;
  overflow-y: auto;

  & span {
    max-width: 350px;
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #000;
    font-size: 14px;
    display: block;
    cursor: pointer;
    transition: .3s;
    padding: 10px;
    box-sizing: border-box;

    &:hover {
      background: #e4e4e4;
    }
    &:first-child {
      border-radius: 5px 5px 0 0;
    }
    &:last-child {
      border-radius: 0 0 5px 5px;
    }
  }
  &::-webkit-scrollbar-thumb {
    background-color: #6e6e6e;
  }
`

S.InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 350px;
  gap: 10px;

  & > button {
    margin-left: auto;
  }
  & > div {
    display: flex;
    justify-content: space-between;
  }
`

S.Input = styled.input`
  border: none;
  height: 35px;
  background: ${({ theme }) => theme.inputBg};
  padding: 10px;
  width: 350px;
  box-sizing: border-box;
  border-radius: 5px;
  font-family: 'Poppins';
  font-size: 14px;

  &:focus {
    outline: none;
  }
`

S.Button = styled.button`
  background: ${({ theme, color }) => color ? theme.red : theme.linear};
  border: none;
  color: #fff;
  width: fit-content;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 130px;
  height: 35px;
  box-sizing: border-box;
  border-radius: 5px;
  cursor: pointer;
`