import { useState } from "react";
import styled from "styled-components";
import Cookies from "universal-cookie";
import { getTranslate, getValue, prepareFormData } from "../contexts/Utils";
import Api from "../contexts/Api";
import { useNotification } from "./UseNotification";
import Formfields from '../components/Formfields';

const ModalReview = (props) => {
  const cookies = new Cookies();
  const user = cookies.get('nudge_user');
  const { data = {}, setModalData, lang, setMissions, setCurrentMission } = props;
  const { notification } = useNotification();
  const [score, setScore] = useState([1, 0, 0, 0, 0]);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  // 
  // ─── HANDLE SUBMIT REVIEW ───────────────────────────────────────
  //
  const handleSubmit = async (e, review = true) => {
    e.preventDefault();

    // loading is to prevent from multiple submit onClick
    if (loading) return;
    setLoading(true);

    let params, message, reviewQuery = {};
    if (review) {
      // 
      // ─── POST REVIEW ───────────────────────────────────────
      //
      const formData = prepareFormData({ formId: 'form' });
      if (formData.errors) {
        setLoading(false);
        return setErrors(formData.errors)
      };

      const queryScore = score.reduce((total, n) => total + n, 0);
      const user_infos = `${user.firstname} ${user.name}`;

      params = {
        data: { fields: { score: queryScore, comment: formData.fields.comment, user_infos }, mission_id: data.id },
        endpoint: '/reviews',
        method: 'POST'
      };

      reviewQuery = await Api(params);

      message = reviewQuery.success ? getTranslate(data.ended ? 'mission_finished' : 'review_published', lang) : reviewQuery.message;
      notification({ variant: reviewQuery.success ? 'success' : 'error', message });
    }
    else reviewQuery = { success: true };

    // 
    // ─── UPDATE MISSION ───────────────────────────────────────
    //
    let queryData = { fields: {}, mission_id: data.id };
    queryData.fields.status = data.ended ? 6 : 1;

    if (!data.ended) {
      queryData.fields.expert_id = null;
      queryData.fields.time_expert_assigned = null;
    }

    params = {
      data: queryData,
      endpoint: '/missions',
      method: 'PUT'
    };

    const missionQuery = await Api(params);

    if (!data.ended) {
      message = missionQuery.success ? getTranslate('mission_unassigned', lang) : missionQuery.message
      notification({ variant: missionQuery.success ? 'success' : 'error', message: message });
    }

    // 
    // ─── UPDATE DISPLAY ───────────────────────────────────────
    //
    if ((reviewQuery.success && missionQuery.success) || (reviewQuery.success && !data.ended)) {
      // close modal if success
      setModalData({});
      setLoading(false);

      // update missions list
      setMissions(prev => {
        let obj = { ...prev };
        delete obj[data.id];
        setCurrentMission(Object.keys(obj).length ? Object.keys(obj)[0] : '');
        return obj;
      })
    }
  }

  // 
  // ─── STARS COMPONENT ───────────────────────────────────────
  //
  const Stars = () => {
    // update score
    const handleScore = (max) => {
      if (score === max) return;
      setScore(Array.from([0, 0, 0, 0, 0], (x, i) => i <= max ? x + 1 : 0));
    }

    return <S.Stars>
      {score.map((e, i) =>
        e ? <svg onClick={() => handleScore(i)} key={i} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z" /></svg> :
          <svg onClick={() => handleScore(i)} key={i} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M287.9 435.9L150.1 509.1C142.9 513.4 133.1 512.7 125.6 507.4C118.2 502.1 114.5 492.9 115.1 483.9L142.2 328.4L31.11 218.2C24.65 211.9 22.36 202.4 25.2 193.7C28.03 185.1 35.5 178.8 44.49 177.5L197.7 154.8L266.3 13.52C270.4 5.249 278.7 0 287.9 0C297.1 0 305.5 5.25 309.5 13.52L378.1 154.8L531.4 177.5C540.4 178.8 547.8 185.1 550.7 193.7C553.5 202.4 551.2 211.9 544.8 218.2L433.6 328.4L459.9 483.9C461.4 492.9 457.7 502.1 450.2 507.4C442.8 512.7 432.1 513.4 424.9 509.1L287.9 435.9zM226.5 168.8C221.9 178.3 212.9 184.9 202.4 186.5L64.99 206.8L164.8 305.6C172.1 312.9 175.5 323.4 173.8 333.7L150.2 473.2L272.8 407.7C282.3 402.6 293.6 402.6 303 407.7L425.6 473.2L402.1 333.7C400.3 323.4 403.7 312.9 411.1 305.6L510.9 206.8L373.4 186.5C362.1 184.9 353.1 178.3 349.3 168.8L287.9 42.32L226.5 168.8z" /></svg>
      )}
    </S.Stars>
  }

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return Boolean(Object.keys(data).length) && <S.Container id="form">
    <h3>{getTranslate('rate_expert', lang)}</h3>
    <Stars />
    <Formfields field={{ component: "text", multiline: true, name: "comment", label: getTranslate('comment', lang), required: true, error: getValue(errors, ['comment', 'message']) }} />
    <S.ButtonsContainer>
      {!data.ended && <S.Button red={true} onClick={(e) => handleSubmit(e, false)} type="submit" value={getTranslate('no_thanks', lang)} />}
      <S.Button alone={data.ended} onClick={(e) => handleSubmit(e)} type="submit" value={getTranslate('validate', lang)} />
    </S.ButtonsContainer>
  </S.Container>
}

export default ModalReview;

// 
// ─── STYLE DEFINITION ───────────────────────────────────────
//
const S = {}
S.Container = styled.form`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  background: ${({ theme }) => theme.card};
  padding: 20px;
  z-index: 10;
  min-width: 400px;

  & *:not(.Mui-error, input[type="submit"]) {
    color: ${({ theme }) => theme.text} !important;
    border-color: ${({ theme }) => theme.text} !important;
  }
  & label.Mui-error {
    color: #d32f2f !important;
  }
  & div.Mui-error > input, div.Mui-error > textarea {
    border-color: #d32f2f !important;
  }

  & h3 {
    color: ${({ theme }) => theme.text};
    margin: 0;
    margin-bottom: 20px;
  }

  @media (max-width: 520px) {
    min-width: unset;
    width: 80%;
  }
`

S.ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

S.Button = styled.input`
  background: ${({ theme, red }) => red ? theme.red : theme.linear};
  border: none;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  padding: 0 35px;
  height: 35px;
  min-height: 35px;
  box-sizing: border-box;
  border-radius: 5px;
  width: fit-content;
  margin-top: 30px;
  cursor: pointer;
  margin-left: ${({ alone }) => alone ? 'auto' : 'unset'};
`

S.Stars = styled.div`
  display: flex;
  margin-bottom: 25px;

  & svg {
    width: 25px;
    height: 25px;
    fill: ${({ theme }) => theme.title};
  }
`