import { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import styled from "styled-components";
import Cookies from "universal-cookie";
import { getTranslate, getValue, prepareFormData } from "../contexts/Utils";
import Api from '../contexts/Api';
import { useNotification } from "../components/UseNotification";
import Formfields from "../components/Formfields";
import SwitchTheme from "../components/SwitchTheme";

const ForgotPassword = ({ lang }) => {
  const { notification } = useNotification();
  const navigate = useNavigate();
  const cookies = new Cookies();
  const user = cookies.get('nudge_user');
  const [errors, setErrors] = useState({});

  // 
  // ─── HANDLE SUBMIT ───────────────────────────────────────
  //
  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = prepareFormData({ formId: 'form' });
    if (data.errors) return setErrors(data.errors);

    const params = {
      data: { fields: data.fields },
      endpoint: '/users/password/reset'
    }

    const resetPassword = await Api(params)
    notification({ variant: resetPassword.success ? 'success' : 'error', message: resetPassword.message })
  }

  return user ? <Navigate to="/" /> : <S.Container id='form'>
    <S.BackContainer onClick={() => navigate('/connexion')}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M448 256C448 264.8 440.6 272 431.4 272H54.11l140.7 149.3c6.157 6.531 5.655 16.66-1.118 22.59C190.5 446.6 186.5 448 182.5 448c-4.505 0-9.009-1.75-12.28-5.25l-165.9-176c-5.752-6.094-5.752-15.41 0-21.5l165.9-176c6.19-6.562 16.69-7 23.45-1.094c6.773 5.938 7.275 16.06 1.118 22.59L54.11 240h377.3C440.6 240 448 247.2 448 256z" /></svg>
    </S.BackContainer>
    <h1>{getTranslate('reset_password')}</h1>
    <Formfields field={{ type: "email", component: "text", name: "email", label: "Email", required: true, error: getValue(errors, ['email', 'message']) }} />
    <S.Button onClick={handleSubmit}>{getTranslate('reset', lang)}</S.Button>
    <SwitchTheme />
  </S.Container>
}

export default ForgotPassword;

const S = {}
S.Container = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  align-content: center;
  justify-content: center;
  gap: 20px;
  background: ${({ theme }) => theme.card};
  border-radius: 10px;
  padding: 30px;
  
  & > h1 {
    color: ${({ theme }) => theme.text};
    font-size: 25px;
    margin-top: 0;
  }

  & .formfield_container {
    margin-bottom: 10px;
  }

  & *:not(.Mui-error, button) {
    color: ${({ theme }) => theme.text} !important;
    border-color: ${({ theme }) => theme.text} !important;
  }
  & label.Mui-error {
    color: #d32f2f !important;
  }
  & div.Mui-error > input, div.Mui-error > textarea {
    border-color: #d32f2f !important;
  }
`

S.Button = styled.button`
  background: ${({ theme }) => theme.linear};
  border: none;
  color: #fff !important;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  min-height: 35px;
  height: 35px;
  width: fit-content;
  box-sizing: border-box;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  min-width: 140px;
  margin-bottom: 20px;
`

S.BackContainer = styled.div`
  width: 100%;
  cursor: pointer;

  & > svg {
    fill: ${({ theme }) => theme.text};
    height: 20px;
  }
`