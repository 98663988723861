import Cookies from 'universal-cookie';

const Api = (opt) => {
  const cookies = new Cookies();
  const userData = cookies.get('nudge_user') || {};
  let endpoint = opt.endpoint;

  // 
  // ─── CREATION DU HEADER NECESSAIRE A LA REQUETE ───────────────────────────────────────
  //
  let myHeaders = new Headers();
  if (!opt.removeHeader) {
    myHeaders.append("Content-Type", "application/json");
  }

  // 
  // ─── DEFINITION DE LA METHODE A UTILISER ───────────────────────────────────────
  //
  let method = opt.method || 'POST'

  // 
  // ─── AJOUT DES OPTIONS DANS LE HEADER ───────────────────────────────────────
  //
  let requestOptions = {
    method,
    headers: myHeaders,
    redirect: 'follow',
    credentials: 'include'
  }

  // 
  // ─── AJOUT DES DONNEES SOUS FORME DE JSON STRINGIFY ───────────────────────────────────────
  //
  if (['POST', 'PUT', 'DELETE'].includes(method)) {
    const data = opt.data || {};
    if (userData) {
      data.user_id = userData.user_id
    }
    requestOptions = { ...requestOptions, body: opt.removeHeader ? data : JSON.stringify(data) }
  } else {
    // Automatically add query params
    endpoint += `?user_id=${userData.user_id}`;
    if (opt.query) {
      Object.entries(opt.query).map(([key, value]) => {
        endpoint += `&${key}=${value}`
      })
    }
  }

  // 
  // ─── API CONFIGURATIONS ───────────────────────────────────────
  //
  const domain = window.location.hostname;
  let configs = {}

  switch (domain) {
    case "app.nudgetech.fr": {
      configs = {
        path: 'nudge',
        url: 'api.olwe.fr',
      }
      break;
    }
    case "nudge-app.agence-cactus.fr": {
      configs = {
        path: 'nudge',
        url: 'api.olwe.fr',
      }
      break;
    }
    default:
      configs = {
        path: 'gt_nudge',
        url: 'api.olwedev.ovh',
      }
      break;
  }

  // 
  // ─── REQUETE A L'API ───────────────────────────────────────
  //
  return fetch(`https://api.olwedev.ovh/${configs.path}${endpoint}`, requestOptions)
    .then(response => response.json())
    .then(result => result)
    .catch(error => console.log('error', error));
}

export default Api;