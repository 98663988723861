import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { getTranslate } from "../contexts/Utils";
import Api from '../contexts/Api';
import { useNotification } from "../components/UseNotification";
import Cookies from "universal-cookie";

const Users = ({ lang }) => {
  const cookies = new Cookies();
  const user = cookies.get('nudge_user');
  const { notification } = useNotification();
  const [users, setUsers] = useState({ list: {}, filtered: {} });
  const navigate = useNavigate();
  const [filter, setFilter] = useState(0);
  const filters = [{ label: 'all_users', i: 0 }, { label: 'industrial', i: 1 }, { label: 'expert', i: 2 }]
  const roleLabel = (window._DATA.types && window._DATA.types.user_role) || {};
  const [modalActive, setModalActive] = useState({ active: false });

  // 
  // ─── GET USERS ───────────────────────────────────────
  //
  useEffect(() => {
    const getUsers = async () => {
      const fields = [
        'user_id',
        'role',
        'status',
        'firstname',
        'name',
        'email',
        'phone'
      ]

      const params = {
        endpoint: '/users',
        method: 'GET',
        query: { deleted: 0, fields }
      }
      const usersQuery = await Api(params);
      setUsers({ list: usersQuery.data, filtered: usersQuery.data });
    }

    getUsers();
  }, [])

  // 
  // ─── HANDLE FILTERS ───────────────────────────────────────
  //
  const handleFilter = (i) => {
    // all users
    if (i === 0) {
      setFilter(0);
      setUsers(state => ({ ...state, filtered: users.list }));
      return users.list;
    }

    // by role
    setFilter(i);
    const obj = Object.entries(users.list);
    const filtered = obj.filter(([key, val]) => val.role === i);
    setUsers(state => ({ ...state, filtered: Object.fromEntries(filtered) }))
    return Object.fromEntries(filtered);
  }

  // 
  // ─── HANDLE SEARCH ───────────────────────────────────────
  //
  const handleSearchChange = async (e) => {
    let obj = {};

    // reset list if no search value
    // if (!e.target.value) return handleFilter(filter);
    if (!e.target.value || !Object.keys(users.filtered).length) {
      obj = Object.entries(handleFilter(filter));
      if (!e.target.value) return;
    }

    // filter by firstname-name-email-phone
    if (!Object.keys(obj).length) obj = Object.entries(users.filtered);
    const filtered = obj.filter(([key, val]) => {
      const nameToMatch = `${[val.firstname]} ${[val.name]} ${[val.email]} ${[val.phone]}`.toLocaleLowerCase();
      const filterToMatch = filter === 0 || val.role === filter;
      return nameToMatch.includes(e.target.value) && filterToMatch;
    })

    setUsers(state => ({ ...state, filtered: Object.fromEntries(filtered) }))
  }

  // 
  // ─── HANDLE VALIDATE EXPERT ───────────────────────────────────────
  //
  const handleValidate = async (id) => {
    const params = {
      endpoint: '/users',
      method: 'PUT',
      data: { fields: { status: 2 }, user_edit: id }
    }

    const query = await Api(params);
    notification({ variant: query.success ? 'success' : 'error', message: query.message });

    // update current obj
    const obj = { ...users };
    obj.list[id].status = 2;
    if (obj.filtered[id]) obj.filtered[id].status = 2;
    setUsers(obj);
  }

  // 
  // ─── HANDLE DELETE USER ───────────────────────────────────────
  //
  const handleDelete = async (id, role) => {
    // delete user in db
    const userParams = {
      endpoint: '/users',
      method: 'DELETE',
      data: { fields: {}, user_delete: id }
    }

    const userQuery = await Api(userParams);

    if (userQuery.success) {
      const message = userQuery.success ? getTranslate('user_deleted', lang) : userQuery.message
      notification({ variant: userQuery.success ? 'success' : 'error', message });

      // update users list
      const obj = { ...users };
      delete obj.list[id];
      if (obj.filtered[id]) delete obj.filtered[id];
      setUsers(obj);

      // pro missions management
      if (role === 1) {
        // mission dispo -> supprimée
        let missionsParams = {
          endpoint: '/missions',
          method: 'PUT',
          data: { fields: { deleted: 1, status: 7 }, user_create: id, status: [1] }
        }
        await Api(missionsParams);

        // mission en cours -> annulée
        missionsParams = {
          endpoint: '/missions',
          method: 'PUT',
          data: { fields: { status: 7 }, user_create: id, status: [2, 3, 4, 5] }
        }
        await Api(missionsParams);
      }
      // expert missions management
      else if (role === 2) {
        // mission en cours -> expert retiré
        let missionsParams = {
          endpoint: '/missions',
          method: 'PUT',
          data: { fields: { status: 1, expert_id: null, time_expert_assigned: null }, expert_id: id, status: [2, 3, 4] }
        }
        await Api(missionsParams);
      }

      // close modal
      handleModal();
    }
  }

  // 
  // ─── HANDLE MODAL ───────────────────────────────────────
  //
  const handleModal = (name, id, role) => {
    setModalActive(prev => {
      document.body.style.overflow = prev ? 'unset' : 'hidden';
      if (prev.active) {
        return { active: false }
      }
      return { active: true, name, id, role }
    })
  }

  // 
  // ─── MODAL DELETE ───────────────────────────────────────
  //
  const ModalDelete = () => <S.ModalContainer>
    <p>Supprimer l'utilisateur {modalActive.name} ?</p>
    <S.ButtonsContainer>
      <S.Button error={true} onClick={handleModal}>{getTranslate('cancel', lang)}</S.Button>
      <S.Button onClick={() => handleDelete(modalActive.id, modalActive.role)}>{getTranslate('validate', lang)}</S.Button>
    </S.ButtonsContainer>
  </S.ModalContainer>

  return <S.Container>
    <h1>{getTranslate('users', lang)}</h1>
    {Boolean(Object.keys(users).length) && <>
      <S.Header>
        <S.Filters>
          {filters.map(e =>
            <S.Filter
              key={e.i}
              onClick={() => handleFilter(e.i)}
              active={filter === e.i}
            >
              {getTranslate(e.label, lang)}
            </S.Filter>
          )}
        </S.Filters>
        <S.Search type='search' placeholder={getTranslate('search', lang)} onChange={handleSearchChange} />
      </S.Header>
      <S.Columns>
        <span className="role">{getTranslate('role', lang)}</span>
        <span className="name">{getTranslate('name', lang)}</span>
        <span className="email">Email</span>
        <span className="phone">{getTranslate('phone', lang)}</span>
      </S.Columns>
      {Object.values(users.filtered).map((e, i) => <S.Card key={i}>
        <S.CardLeft>
          <span className="role"><span>{getTranslate(roleLabel[e.role].label, lang) || roleLabel[e.role].label}</span></span>
          <span className="name">{e.firstname} {e.name}</span>
          <span className="email">{e.email}</span>
          <span className="phone">{e.phone}</span>
        </S.CardLeft>
        <S.CardRight>
          <div>
            <svg onClick={() => navigate(`/utilisateurs/editer/${e.user_id}`)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M386.7 22.63C411.7-2.365 452.3-2.365 477.3 22.63L489.4 34.74C514.4 59.74 514.4 100.3 489.4 125.3L269 345.6C260.6 354.1 249.9 359.1 238.2 362.7L147.6 383.6C142.2 384.8 136.6 383.2 132.7 379.3C128.8 375.4 127.2 369.8 128.4 364.4L149.3 273.8C152 262.1 157.9 251.4 166.4 242.1L386.7 22.63zM454.6 45.26C442.1 32.76 421.9 32.76 409.4 45.26L382.6 72L440 129.4L466.7 102.6C479.2 90.13 479.2 69.87 466.7 57.37L454.6 45.26zM180.5 281L165.3 346.7L230.1 331.5C236.8 330.2 242.2 327.2 246.4 322.1L417.4 152L360 94.63L189 265.6C184.8 269.8 181.8 275.2 180.5 281V281zM208 64C216.8 64 224 71.16 224 80C224 88.84 216.8 96 208 96H80C53.49 96 32 117.5 32 144V432C32 458.5 53.49 480 80 480H368C394.5 480 416 458.5 416 432V304C416 295.2 423.2 288 432 288C440.8 288 448 295.2 448 304V432C448 476.2 412.2 512 368 512H80C35.82 512 0 476.2 0 432V144C0 99.82 35.82 64 80 64H208z" /></svg>
            <span>{getTranslate('edit', lang)}</span>
          </div>
          {e.status === 1 && e.role === 2 && <div>
            <svg onClick={() => handleValidate(e.user_id)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M443.3 100.7C449.6 106.9 449.6 117.1 443.3 123.3L171.3 395.3C165.1 401.6 154.9 401.6 148.7 395.3L4.686 251.3C-1.562 245.1-1.562 234.9 4.686 228.7C10.93 222.4 21.06 222.4 27.31 228.7L160 361.4L420.7 100.7C426.9 94.44 437.1 94.44 443.3 100.7H443.3z" /></svg>
            <span>{getTranslate('validate', lang)}</span>
          </div>}
          {user.user_id !== e.user_id && <div>
            <svg onClick={() => handleModal(`${e.firstname} ${e.name}`, e.user_id, e.role)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M432 64C440.8 64 448 71.16 448 80C448 88.84 440.8 96 432 96H413.7L388.2 452.6C385.9 486.1 357.1 512 324.4 512H123.6C90.01 512 62.15 486.1 59.75 452.6L34.29 96H16C7.164 96 0 88.84 0 80C0 71.16 7.164 64 16 64H111.1L137 22.56C145.8 8.526 161.2 0 177.7 0H270.3C286.8 0 302.2 8.526 310.1 22.56L336.9 64H432zM177.7 32C172.2 32 167.1 34.84 164.2 39.52L148.9 64H299.1L283.8 39.52C280.9 34.84 275.8 32 270.3 32H177.7zM381.6 96H66.37L91.67 450.3C92.87 467 106.8 480 123.6 480H324.4C341.2 480 355.1 467 356.3 450.3L381.6 96z" /></svg>
            <span>{getTranslate('delete', lang)}</span>
          </div>}
        </S.CardRight>
      </S.Card>)}
    </>
    }
    {modalActive.active && <>
      <ModalDelete />
      <S.Background onClick={handleModal} />
    </>}
  </S.Container>
}

export default Users;

const S = {}
S.Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;

  & > h1 {
    text-transform: uppercase;
    color: ${({ theme }) => theme.text};
    font-size: 23px;
    margin: 0;
    font-weight: bold;
  }
`

S.Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`

S.Filters = styled.div`
  display: flex;
  gap: 8px;
`

S.Filter = styled.span`
  padding: 6px 15px;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  background: ${({ active, theme }) => active ? theme.title : theme.card};
  color: ${({ active, theme }) => active ? theme.textInverse : theme.text};
`

S.Search = styled.input`
  height: 35px;
  border: none;
  background: #fff;
  width: 300px;
  border-radius: 5px;
  padding: 0 15px;
  box-sizing: border-box;
  font-size: 15px;
  font-family: 'Poppins';

  &:focus {
    outline: none;
  }
`

S.Card = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  background: ${({ theme }) => theme.card};
  border-radius: 10px;
  padding: 10px 20px;
  box-sizing: border-box;
  font-weight: lighter;
`

S.CardLeft = styled.div`
  display: flex;
  gap: 20px;
  font-size: 15px;

  & .role {
    display: flex;
    min-width: 70px;

    & > span {
      font-size: 12px;
      background: #00000033;
      border-radius: 5px;
      padding: 3px 7px;
    }
  }
  & > span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  & .name {
    min-width: 160px;
    max-width: 160px;
  }
  & .email {
    min-width: 240px;
    max-width: 240px;
  }
  & .phone {
    min-width: 100px;
    max-width: 100px;
  }
`

S.CardRight = styled.div`
  display: flex;
  gap: 20px;

  & > div {
    position: relative;
    display: flex;
    justify-content: center;

    &:hover span {
      opacity: 1;
    }
    & svg {
      width: 18px;
      height: 18px;
      fill: ${({ theme }) => theme.text};
      cursor: pointer;
    }
    & span {
      z-index: 100;
      opacity: 0;
      position: absolute;
      color: ${({ theme }) => theme.text};
      border-radius: 5px;
      background: ${({ theme }) => theme.card};
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      padding: 3px 8px;
      font-size: 13px;
      top: calc(100% + 10px);
      transition: .3s;
      font-weight: lighter;

      @media(max-width: 1100px) {
        display: none;
      }
    }
  }
`

S.Columns = styled(S.CardLeft)`
  padding: 0 20px;

  & .role {
    background: none;
    font-size: unset;
  }
  & .phone {
    max-width: unset;
  }
`

S.ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  background: ${({ theme }) => theme.card};
  padding: 20px;
  z-index: 101;
  min-width: 350px;

  & h3 {
    color: ${({ theme }) => theme.text};
    margin: 0;
    margin-bottom: 20px;
  }

  @media (max-width: 520px) {
    min-width: unset;
    width: 80%;
  }
`

S.ButtonsContainer = styled.div`
  display: flex;
  gap: 20px;
`

S.Button = styled.button`
  background: ${({ theme, error }) => error ? theme.red : theme.linear};
  border: none;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  padding: 0 35px;
  height: 35px;
  min-height: 35px;
  box-sizing: border-box;
  border-radius: 5px;
  width: fit-content;
  margin-top: 30px;
  cursor: pointer;
`

S.Background = styled.div`
  inset: 0;
  z-index: 100;
  position: fixed;
  background: #000000a3;
`