import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import Cookies from "universal-cookie";
import { useDropzone } from 'react-dropzone';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { formatDate, getTranslate, getValue, prepareFormData } from "../contexts/Utils";
import Api from '../contexts/Api';
import { useNotification } from "../components/UseNotification";
import Formfields from "../components/Formfields";
import SelectAutocomplete from "../components/SelectAutocomplete";

const CreateMission = ({ lang = 'fr', edit = false }) => {
  const { notification } = useNotification();
  const navigate = useNavigate();
  const { uuid } = useParams();
  const { pathname } = useLocation();
  const cookies = new Cookies();
  const userData = cookies.get('nudge_user');
  const [errors, setErrors] = useState({});
  const [mission, setMission] = useState({});
  const [description, setDescription] = useState('');
  const selectedCategories = useRef([]);

  let categories = [];
  if (window._DATA.categories) categories = window._DATA.categories.map(e => ({ id: e.category_id, name: e.name }));

  // 
  // ─── GET MISSION INFO ───────────────────────────────────────
  //
  useEffect(() => {
    const getUsersInfo = async () => {
      const params = {
        query: { mission_id: uuid },
        endpoint: '/missions',
        method: 'GET'
      }
      const missionQuery = await Api(params);

      if (missionQuery.success) {
        const data = Object.values(missionQuery.data)[0];

        // can only modify if status is 1 (available)
        if (data.status !== 1) navigate('/');

        setMission(data)
        selectedCategories.current = (data.categories && categories.filter(e => data.categories.includes(e.id))) || [];
        if (data.content)
          setDescription(JSON.parse(data.content)[lang])
      }
    }
    if (uuid) getUsersInfo();
  }, [userData.user_id, uuid, lang, navigate])

  // 
  // ─── RE RENDER WHEN ROUTE CHANGES ───────────────────────────────────────
  //
  useEffect(() => {
    setMission({});
    setErrors({});
    selectedCategories.current = [];
    setDescription("");
    setFiles([]);
  }, [pathname])

  // 
  // ─── HANDLE SUBMIT ───────────────────────────────────────
  //
  const handleSubmit = async (e) => {
    e.preventDefault();

    let data = prepareFormData({ formId: 'form' });
    if (data.errors) return setErrors(data.errors);

    const currentContent = mission.content ? JSON.parse(mission.content) : {};
    data.fields.content = JSON.stringify({ ...currentContent, [lang]: description });

    // -1h for db
    let time_online = new Date(data.fields.time_online);
    let formatTime = formatDate({ time: new Date(time_online).setHours(time_online.getHours() - 1), display: 'db' })
    data.fields.time_online = formatTime;

    data.fields.user_id = userData.user_id;
    data.fields.categories = selectedCategories.current.map(e => e.id).join(',');

    const formData = new FormData();
    files.forEach(file => formData.append('files[]', file))
    formData.append('fields', JSON.stringify(data.fields));

    if (uuid) {
      formData.append('mission_id', mission.mission_id)
      formData.append('gallery', mission.gallery)
    }

    const params = {
      data: formData,
      endpoint: '/missions',
      removeHeader: true,
      method: uuid ? 'PUT' : 'POST'
    };

    const missionCreate = await Api(params);
    notification({ variant: missionCreate.success ? 'success' : 'error', message: missionCreate.message })

    if (missionCreate.success) navigate('/');
  }

  //
  // ─── DELETE A FILE ───────────────────────────────────────
  //
  const handleDeleteFile = async (file) => {
    const formData = new FormData();

    if (uuid) {
      formData.append('mission_id', uuid)
      formData.append('gallery', mission.gallery)
      formData.append('deleteFile', true)
      formData.append('fileName', file)
    }

    const params = {
      data: formData,
      endpoint: '/missions',
      removeHeader: true,
      method: 'PUT'
    }

    const query = await Api(params);
    notification({ variant: query.success ? 'success' : 'error', message: query.message })

    // update files list
    if (query.success) {
      setMission((prev) => {
        const newGallery = JSON.parse(prev.gallery).filter(f => f !== file);
        return { ...prev, gallery: JSON.stringify(newGallery) };
      })
    }
  }

  //
  // ─── DROPZONE MANAGEMENT ───────────────────────────────────────
  //
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': []
    },
    maxFiles: 6,
    onDrop: acceptedFiles => {
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));
    }
  });

  const thumbs = files.map(file => (
    <S.Thumb key={file.name}>
      <div>
        <img
          src={file.preview}
          alt={file.name}
          // Revoke data uri after image is loaded
          onLoad={() => { URL.revokeObjectURL(file.preview) }}
        />
      </div>
    </S.Thumb>
  ));

  //
  // ─── QUILL SETTINGS ───────────────────────────────────────
  //
  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
      ['link'],
      ['clean'],
    ],
  };

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
  ];

  // 
  // ─── FORMFIELDS ───────────────────────────────────────
  //
  const formFields = [
    { default: mission.title, size: 'calc(50% - 10px)', component: "text", name: "title", label: getTranslate('title', lang), required: true },
    { default: mission.time_online, maxWidth: '250px', type: 'datetime-local', component: "datepicker", name: "time_online", label: "Date de publication", default: Date.now(), required: true },
  ]

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return ((edit && Object.keys(mission).length) || !edit) && <S.Container>
    <S.Form id='form'>
      <h1>{getTranslate(edit ? 'edit_mission' : 'create_mission', lang)}</h1>
      {formFields.map((e, i) => <Formfields key={i} field={{ ...e, ...{ error: getValue(errors, [e.name, 'message']) } }} />)}
      <S.QuillContainer>
        <span>Description</span>
        <ReactQuill
          theme="snow"
          value={description}
          onChange={(e) => setDescription(e)}
          modules={modules}
          formats={formats}
        />
      </S.QuillContainer>
      <div style={{ width: '100%' }}>
        <S.DropzoneTitle>{getTranslate('pictures', lang)}</S.DropzoneTitle>
        <S.DropzoneContainer>
          <S.Dropzone id="dropzone" {...getRootProps()}>
            <input {...getInputProps()} />
            <p>{getTranslate('drag_files', lang)}</p>
          </S.Dropzone>
          <span>{getTranslate('files', lang)}</span>
          <S.DropzoneThumbs>{thumbs}</S.DropzoneThumbs>
        </S.DropzoneContainer>
        {uuid && mission.gallery && <S.Files>
          {JSON.parse(mission.gallery).map(file => <div key={file}>
            <a href={`https://nudge-app.agence-cactus.fr/img/missions/${uuid}/${file}`}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M180.7 395.3C183.8 398.4 187.9 400 192 400s8.188-1.562 11.31-4.688l144-144c6.25-6.25 6.25-16.38 0-22.62s-16.38-6.25-22.62 0L208 345.4V48C208 39.16 200.8 32 192 32S176 39.16 176 48v297.4L59.31 228.7c-6.25-6.25-16.38-6.25-22.62 0s-6.25 16.38 0 22.62L180.7 395.3zM368 448h-352C7.156 448 0 455.2 0 464S7.156 480 16 480h352c8.844 0 16-7.156 16-16S376.8 448 368 448z" /></svg> {file}</a>
            <svg onClick={() => handleDeleteFile(file)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M432 64C440.8 64 448 71.16 448 80C448 88.84 440.8 96 432 96H413.7L388.2 452.6C385.9 486.1 357.1 512 324.4 512H123.6C90.01 512 62.15 486.1 59.75 452.6L34.29 96H16C7.164 96 0 88.84 0 80C0 71.16 7.164 64 16 64H111.1L137 22.56C145.8 8.526 161.2 0 177.7 0H270.3C286.8 0 302.2 8.526 310.1 22.56L336.9 64H432zM177.7 32C172.2 32 167.1 34.84 164.2 39.52L148.9 64H299.1L283.8 39.52C280.9 34.84 275.8 32 270.3 32H177.7zM381.6 96H66.37L91.67 450.3C92.87 467 106.8 480 123.6 480H324.4C341.2 480 355.1 467 356.3 450.3L381.6 96z" /></svg>
          </div>
          )}
        </S.Files>}
      </div>
      <SelectAutocomplete ref={selectedCategories} array={categories} defaultValue={mission.categories || []} />
    </S.Form>
    <S.Button onClick={handleSubmit}>{getTranslate(uuid ? 'save' : 'create', lang)}</S.Button>
  </S.Container>
}

export default CreateMission;

// 
// ─── STYLE DEFINITION ───────────────────────────────────────
//
const S = {}
S.Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  overflow: auto;
  padding-right: 10px;
  background: ${({ theme }) => theme.card};
  border-radius: 10px;
  padding: 20px;

  & h1 {
    color: ${({ theme }) => theme.title} !important;
    font-size: 21px;
    text-transform: uppercase;
    width: 100%;
    margin: 0;
  }

  & input {
    fill: #fff !important;

    #picker {
    fill: #fff !important;
    }
  }
`

S.Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 30px;

  & .formfield_container {
    margin-bottom: 10px;
  }

  & *:not(.Mui-error, p, #dropzone) {
    color: ${({ theme }) => theme.text} !important;
    border-color: ${({ theme }) => theme.text} !important;
  }
  & label.Mui-error {
    color: #d32f2f !important;
  }
  & div.Mui-error > input, div.Mui-error > textarea {
    border-color: #d32f2f !important;
  }
  & input[type="datetime-local"] {
    color-scheme: ${({ theme }) => theme.colorScheme};
  }
`

S.QuillContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  & .quill {
    min-height: 200px;
    max-height: 290px;
    width: 100%;
    margin-bottom: 42px;

    & .ql-container {
      font-size: 16px;
    }

    .ql-toolbar .ql-stroke {
      fill: none;
      stroke: ${({ theme }) => theme.text};
    }

    .ql-toolbar .ql-fill {
      fill: ${({ theme }) => theme.text};
      stroke: none;
    }

    .ql-toolbar .ql-picker {
      color: ${({ theme }) => theme.text};
    }
  }
  & > span {
    margin-bottom: 10px;
    font-size: 17px;
  }
`

S.DropzoneTitle = styled.h3`
  margin-bottom: 10px;
  margin-top: 0;
  font-size: 17px;
`

S.DropzoneContainer = styled.div`
  width: 100%;
  border: 1px solid #ccc;
  display: flex;
  min-height: 200px;
  padding: 15px;
  box-sizing: border-box;
  flex-direction: column;
  color: #000 !important;

  & > span {
    font-weight: lighter;
    margin-top: 20px;
    margin-bottom: 5px;
  }
`

S.Dropzone = styled.div`
  width: 100%;
  box-sizing: border-box;
  height: 100px;
  background: #f1f1f1;
  border: 2px dashed #cacaca;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  & > p {
    color: #000 !important;
  }
`

S.DropzoneThumbs = styled.div`
  display: flex;
  flex-wrap: wrap;
`

S.Thumb = styled.div`
  display: flex;
  border-radius: 5px;
  border: 1px solid #eaeaea;
  margin-bottom: 8px;
  margin-right: 8px;
  width: 100px;
  height: 100px;
  padding: 4px;

  & > div {
    display: flex;
    min-width: 0;
    overflow: hidden;
  }
  & > img {
    display: block;
    width: auto;
    height: 100%;
  }
`

S.Button = styled.button`
  background: ${({ theme }) => theme.linear};
  border: none;
  color: #fff !important;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  min-height: 35px;
  height: 35px;
  width: fit-content;
  box-sizing: border-box;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  min-width: 140px;
  margin-bottom: 20px;
`

S.Files = styled.div`
  display: flex;
  gap: 15px;
  margin-top: 15px;
  margin-bottom: 10px;

  & > div {
    display: flex;
    gap: 2px;

    & > a {
    width: fit-content;
    padding: 4px 10px;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.text};
    gap: 10px;
    font-weight: lighter;
    background: #00000069;
    border-radius: 5px;
    font-size: 14.5px;
    transition: .3s;

    &:hover {
      background: #00000091;
    }
    & svg {
      fill: ${({ theme }) => theme.text};
      width: 14px;
    }
  }

  & > svg {
    cursor: pointer;
    fill: ${({ theme }) => theme.text};
    width: 16px;
    padding: 3px 8px;
    background: ${({ theme }) => theme.red};
    border-radius: 5px;
  }
  }

  & span {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 13px;
    height: 25px;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.title};
  }
`