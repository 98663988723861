import { useEffect, useState } from "react";
import styled from "styled-components";
import { getTranslate, getValue, prepareFormData } from "../contexts/Utils";
import Api from '../contexts/Api';
import Formfields from "../components/Formfields";
import { useNotification } from "../components/UseNotification";

const Tools = ({ lang }) => {
  const { notification } = useNotification();
  const [settings, setSettings] = useState({});
  const [errors, setErrors] = useState({});

  // 
  // ─── GET CURRENT SETTINGS ON LOAD ───────────────────────────────────────
  //
  useEffect(() => {
    const getSettings = async () => {
      const params = {
        endpoint: '/settings',
        method: 'GET',
      }
      const query = await Api(params);
      if (query.success) setSettings(query.data);
    }

    getSettings();
  }, [])

  // 
  // ─── HANDLE SUBMIT ───────────────────────────────────────
  //
  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = prepareFormData({ formId: 'form' });
    if (formData.errors) return setErrors(formData.errors);

    const params = {
      endpoint: '/settings',
      method: 'PUT',
      data: { fields: formData.fields }
    }
    const query = await Api(params);
    notification({ variant: query.success ? 'success' : 'error', message: query.message })
  }

  // 
  // ─── FORMFIELDS ───────────────────────────────────────
  //
  const formfields = [
    { default: settings.price_hourly && Number(settings.price_hourly.value), size: 'calc(50% - 10px)', type: 'number', component: "text", name: "price_hourly", label: getTranslate('price_hourly', lang), required: true },
    { default: settings.commission && Number(settings.commission.value), size: 'calc(50% - 10px)', type: 'number', component: "text", name: "commission", label: 'Commission (%)', required: true },
    { default: settings.stripe_trial_period_days && Number(settings.stripe_trial_period_days.value), size: 'calc(50% - 10px)', type: 'number', component: "text", name: "stripe_trial_period_days", label: getTranslate('trial_period', lang), required: true },
  ]

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return Boolean(Object.keys(settings).length) && <S.Container id="form">
    {formfields.map(e => <Formfields key={e.name} field={{ ...e, ...{ error: getValue(errors, [e.name, 'message']) } }} />)}
    <S.ButtonContainer>
      <S.Button type="submit" onClick={handleSubmit} value={getTranslate('save', lang)} />
    </S.ButtonContainer>
  </S.Container>
}

export default Tools;

// 
// ─── STYLE DEFINITION ───────────────────────────────────────
//
const S = {}
S.Container = styled.form`
  display: flex;
  gap: 20px;
  background: ${({ theme }) => theme.card};
  border-radius: 10px;
  padding: 20px;
  padding-top: 25px;
  box-sizing: border-box;
  flex-wrap: wrap;

  & > h2 {
    color: ${({ theme }) => theme.text};
    text-transform: uppercase;
    font-size: 24px;
    margin: 0;
    margin-bottom: 10px;
    width: 100%;
  }
  & .formfield_container {
    margin-bottom: 10px;
  }

  & * {
    color: ${({ theme }) => theme.text} !important;
    border-color: ${({ theme }) => theme.text} !important;
  }
`

S.ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`

S.Button = styled.input`
  background: ${({ theme }) => theme.linear};
  border: none;
  color: #fff !important;
  width: fit-content;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  padding: 0 35px;
  height: 35px;
  box-sizing: border-box;
  border-radius: 5px;
  cursor: pointer;
`