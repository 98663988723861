import styled from "styled-components";

const CGU = () => {
  return <S.Container>
    <h1>MENTIONS LEGALES</h1>

    <span>En vigueur au 01/01/2023</span>

    <br />
    <br />

    <p>Conformément aux dispositions des Articles 6-III et 19 de la Loi n°2004-575 du 21 juin 2004 pour la Confiance dans l’économie numérique, dite L.C.E.N, il est porté à la connaissance des utilisateurs et visiteurs, ci-après « l’Utilisateur », du site <a href="https://www.nudgetech.fr">https://www.nudgetech.fr</a>, ci-après « le Site », les présentes mentions légales.</p>
    <p>La connexion et la navigation sur le Site par l’Utilisateur impliquent l’acceptation intégrale et sans réserve des présentes mentions légales.</p>
    <p>Ces dernières sont accessibles sur le Site à la rubrique « Mentions légales ».</p>

    <h2>ARTICLE 1 – L’EDITEUR</h2>

    <p>Ci-après, « l’Editeur » :</p>
    <p>NUDGE TECH (EURL)</p>
    <p>Capital social de 4000 euros</p>
    <p>Immatriculé au RCS de COLMAR, sous le numéro 921082574</p>
    <p>23A rue de FESSENHEIM, 68740 BALGAU</p>
    <p>mail : regis.schmidt@nudgetech.fr / regis.schmidt@wanadoo.fr</p>
    <p>téléphone : 0783310746</p>

    <h2>ARTICLE 2 – L’HEBERGEUR</h2>

    <p>L’hébergeur du site <a href="https://www.nudgetech.fr">https://www.nudgetech.fr</a> est la société CACTUS, sise au 8 rue des Cailles, 68270 Ruelisheim (France).</p>
    <p>Numéro de téléphone : 03.89.52.63.10</p>
    <p>Adresse mail : contact@agence-cactus.fr</p>

    <h2>ARTICLE 3 – ACCES AU SITE</h2>

    <p>Le site est accessible en tout endroit, 7j/7, 24h/24 sauf en cas de force majeure et d’interruption programmée ou non, pouvant découler d’une nécessité de maintenance.</p>
    <p>En cas de modification, interruption ou suspension du Site, l’Editeur ne saurait être tenu pour responsable.</p>

    <h2>ARTICLE 4 – COLLECTE DES DONNEES</h2>

    <p>Le site assure à l’Utilisateur une collecte et un traitement d’informations personnelles dans le respect de la vie privée conformément à la loi n°78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et aux libertés.</p>
    <p>En vertu de la loi Informatique et Libertés, en date du 6 janvier 1978, l’utilisateur dispose d’un droit d’accès, de rectification, de suppression et d’opposition de ses données personnelles.</p>
    <p>Toute  utilisation, reproduction, diffusion, commercialisation, modification de tout ou partie du Site, sans autorisation de l’Editeur est prohibée et pourra entraîner des actions et poursuites judiciaires telles que notamment prévues par le Code de la propriété intellectuelle et le Code civil.</p>
  </S.Container>
}

export default CGU;

const S = {}
S.Container = styled.div`
  background: ${({ theme }) => theme.card};
  color: ${({ theme }) => theme.text};
  box-sizing: border-box;
  border-radius: 5px;
  font-weight: lighter;
  font-size: 15px;
  padding: 30px;

  & h1 {
    margin-top: 0;
  }

  & a {
    color: ${({ theme }) => theme.title};
  }

  & p {
    margin: 0
  }
`