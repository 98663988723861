import React, { useContext } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, registerables } from 'chart.js';
import { Context } from '../App';
ChartJS.register(...registerables);

const LineChart = (props) => {
  const { data = [], backgroundColor, borderColor, label = '' } = props;
  const { theme, setTheme } = useContext(Context);

  const labels = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];

  const state = {
    labels,
    datasets: [
      {
        label,
        fill: true,
        lineTension: 0.5,
        backgroundColor: backgroundColor || 'rgba(255, 177, 0,0.2)',
        borderColor: borderColor || 'rgb(255, 177, 0)',
        borderWidth: 3,
        displayColors: false,
        data
      }
    ]
  }

  return (
    <Line
      data={state}
      options={{
        responsive: true,
        scales: {
          y: {
            ticks: {
              beginAtZero: true,
              callback: function (value) { if (value % 1 === 0) { return value; } },
              color: theme === 'light' ? '#000' : '#fff'
            },
            grid: {
              color: '#595959'
            },
          },
          x: {
            grid: {
              color: '#595959'
            },
            ticks: {
              color: theme === 'light' ? '#000' : '#fff'
            }
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        }
      }}
    />
  );
}

export default LineChart;