import { useEffect, useState } from "react";
import styled from "styled-components";
import Cookies from "universal-cookie";
import { getTranslate, getValue } from "../contexts/Utils";
import Api from "../contexts/Api";

const Transactions = ({ lang }) => {
  const cookies = new Cookies();
  const user = cookies.get('nudge_user');
  const [transactions, setTransactions] = useState([]);
  const [subscriptionInvoices, setSubscriptionsInvoices] = useState([]);

  const statusColors = {
    1: {
      color: 'black',
      background: '#e3e3e3',
    },
    2: {
      color: 'white',
      background: '#ba902b',
    },
    3: {
      color: 'white',
      background: '#e36060',
    },
    4: {
      color: 'white',
      background: '#38a438',
    },
    5: {
      color: 'white',
      background: '#e36060',
    },
  }

  useEffect(() => {
    const getTransactions = async () => {
      let query = {
        fields: [
          'transaction_id',
          'transactions.mission_id',
          'missions.title',
          'transactions.status'
        ],
      }

      if (user.role === 1) query.industrial_id = user.user_id;
      else if (user.role === 2) query.expert_id = user.user_id;

      const params = {
        query,
        endpoint: "/transactions",
        method: 'GET'
      }

      const transactionsQuery = await Api(params);
      if (transactionsQuery.success) {
        setTransactions(transactionsQuery.data);
      }
    }

    getTransactions();
  }, [user.role, user.user_id])

  useEffect(() => {
    const getSubscriptionInvoices = async () => {

      const params = {
        endpoint: "/stripe/invoices/subscription",
        method: 'GET'
      }

      const subscription = await Api(params);
      if (subscription.success) {
        setSubscriptionsInvoices(subscription.data);
      }
    }

    user.role === 1 && getSubscriptionInvoices();
  }, [user.role, user.user_id])

  const handleDownload = async (transaction_id, invoice_id) => {
    const params = {
      query: { transaction_id, role: user.role, invoice_id },
      endpoint: "/invoice",
      method: 'GET'
    }

    const downloadQuery = await Api(params);

    // download file
    if (downloadQuery.success) {
      const link = document.createElement('a');
      link.href = "data:application/pdf;base64," + downloadQuery.buffer;
      link.download = `${invoice_id}.pdf`;
      link.click();
    }
  }

  return <S.Container>
    <h1>{getTranslate('my_bills', lang)}</h1>
    <h2>Missions</h2>
    {transactions.length ? <>
      <S.Columns>
        <span className="id">{getTranslate('invoice_id', lang)}</span>
        <span className="status_column">État</span>
        <span className="title">{getTranslate('mission_title', lang)}</span>
        <span className="price">{getTranslate('price_ht', lang)}</span>
      </S.Columns>
      {transactions.map(t => <S.Card key={t.invoice_id}>
        <span className="id"><span>{t.invoice_id}</span></span>
        <span className="status" style={statusColors[t.status]}><span>{getValue(window._DATA, ['types', 'transaction_status', t.status, 'label'])}</span></span>
        <span className="title">{t.title}</span>
        <S.CardRight className="card-right">
          <span className="price">{t.price} €</span>
          <div className="download">
            <svg onClick={() => handleDownload(t.transaction_id, t.invoice_id)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M180.7 395.3C183.8 398.4 187.9 400 192 400s8.188-1.562 11.31-4.688l144-144c6.25-6.25 6.25-16.38 0-22.62s-16.38-6.25-22.62 0L208 345.4V48C208 39.16 200.8 32 192 32S176 39.16 176 48v297.4L59.31 228.7c-6.25-6.25-16.38-6.25-22.62 0s-6.25 16.38 0 22.62L180.7 395.3zM368 448h-352C7.156 448 0 455.2 0 464S7.156 480 16 480h352c8.844 0 16-7.156 16-16S376.8 448 368 448z" /></svg>
            <span>{getTranslate('download', lang)}</span>
          </div>
        </S.CardRight>
      </S.Card>)}
    </>
      : <span>{getTranslate('no_invoice_yet', lang)}</span>}
    {user.role === 1 && <>
      <h2>Abonnement</h2>
      {subscriptionInvoices.length ? <>
        <S.Columns>
          <span className="id">{getTranslate('invoice_id', lang)}</span>
          <span className="title">Désignation de l'abonnement</span>
          <span className="price">{getTranslate('price_ht', lang)}</span>
        </S.Columns>
        {subscriptionInvoices.map(t => <S.Card key={t.invoice_id}>
          <span className="id"><span>{t.invoice_id}</span></span>
          <span className="title">{t.title}</span>
          <S.CardRight className="card-right">
            <span className="price">{t.price / 100} €</span>
            <div className="download">
              <svg onClick={() => {
                const link = document.createElement('a');
                link.href = t.url;
                link.click();
              }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M180.7 395.3C183.8 398.4 187.9 400 192 400s8.188-1.562 11.31-4.688l144-144c6.25-6.25 6.25-16.38 0-22.62s-16.38-6.25-22.62 0L208 345.4V48C208 39.16 200.8 32 192 32S176 39.16 176 48v297.4L59.31 228.7c-6.25-6.25-16.38-6.25-22.62 0s-6.25 16.38 0 22.62L180.7 395.3zM368 448h-352C7.156 448 0 455.2 0 464S7.156 480 16 480h352c8.844 0 16-7.156 16-16S376.8 448 368 448z" /></svg>
              <span>{getTranslate('download', lang)}</span>
            </div>
          </S.CardRight>
        </S.Card>)}
      </>
        : <span>{getTranslate('no_invoice_yet', lang)}</span>}</>}
  </S.Container>
}

export default Transactions;

const S = {}
S.Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;

  & > h1 {
    text-transform: uppercase;
    color: ${({ theme }) => theme.text};
    font-size: 23px;
    margin: 0;
    font-weight: bold;
    margin-bottom: 15px;
  }

  & h2 {
    color: ${({ theme }) => theme.text};
  }
`

S.Card = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  background: ${({ theme }) => theme.card};
  border-radius: 10px;
  padding: 10px 20px;
  font-weight: lighter;

  & .id {
    display: flex;
    min-width: 140px;

    & > span {
      font-size: 12px;
      background: #00000033;
      border-radius: 5px;
      padding: 3px 7px;
    }
  }

  .status{
    display:flex;
    align-items:center;
    padding:2px 6px;
    border-radius:4px;
    span{
      color:inherit !important;
      font-size:0.7em;
    }
  }

  @media(max-width: 800px) {
    flex-wrap: wrap;
    
    & .title {
      margin-left: unset;
      order: 4;
      width: 100%;
    }
    & .card-right {
      order: 3;
    }
  }
  `

S.CardRight = styled.div`
  margin-left: auto;
  display: flex;
  gap: 40px;
  height: fit-content;
  align-items: center;

  & > div {
    position: relative;
    display: flex;
    justify-content: center;

    &:hover span {
      opacity: 1;
    }
    & svg {
      width: 19px;
      height: 19px;
      fill: ${({ theme }) => theme.text};
      cursor: pointer;
    }
    & span {
      z-index: 100;
      opacity: 0;
      position: absolute;
      color: ${({ theme }) => theme.text};
      border-radius: 5px;
      background: ${({ theme }) => theme.card};
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      padding: 3px 8px;
      font-size: 13px;
      top: calc(100% + 10px);
      transition: .3s;
      font-weight: lighter;

      @media(max-width: 1100px) {
        display: none;
      }
    }
  }
  & .price {
    white-space: nowrap;
  }
`

S.Columns = styled(S.Card)`
  padding: 0 20px;
  background: none;
  border-radius: 0;
  font-weight: bolder;
  font-size: 15px;

  & .price {
    margin-left: auto;
    margin-right: 59px;
  }

  @media(max-width: 800px) {
    & .title {
      display: none;
    }
  }
`