import { useState, useEffect, useMemo } from "react";
import { Outlet, Navigate } from "react-router-dom";
import Api from '../contexts/Api';
import Cookies from 'universal-cookie';
import Loader from "./Loader";

const PersistLogin = () => {
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true)
  const cookies = useMemo(() => new Cookies(), []);
  const userData = useMemo(() => cookies.get('nudge_user') || {}, [cookies]);

  // 
  // ─── MANAGE APP CONNECTION ───────────────────────────────────────
  //
  useEffect(() => {

    // 
    // ─── CHECK AUTHENTICATION ───────────────────────────────────────
    //
    const authenticateUser = async () => {
      const params = { endpoint: '/auth/authenticate' }

      const isAuthenticated = await Api(params)

      if (isAuthenticated && isAuthenticated.success) {
        // 
        // ─── RESET COOKIE WITH CURRENT USER INFOS ───────────────────────────────────────
        //
        cookies.remove('nudge_user');
        cookies.set('nudge_user', isAuthenticated.data, {
          expires: new Date(Date.now() + 604800000), // time until expiration
          secure: false, // true if using https
          path: '/' // if not set, will gen cookie for different pages
        })

        // 
        // ─── GET CATEGORIES + TYPES & SETTINGS ───────────────────────────────────────
        //
        const catParams = {
          endpoint: '/categories',
          method: 'GET'
        }
        const tsParams = {
          endpoint: '/data',
          method: 'GET'
        }

        await Promise.all([Api(catParams), Api(tsParams)]).then(values => {
          // 
          // ─── SET WINDOW VARIABLE ───────────────────────────────────────
          //
          window._DATA = {
            categories: values[0].data,
            ...values[1].data
          };

        });
        setAuthenticated(true)
      } else {
        setAuthenticated(false)
        cookies.remove('nudge_user')
      }
      setLoading(false);
    }

    // 
    // ─── IF USER IS LOGGED CHECK AUTHENTIFICATION ───────────────────────────────────────
    //
    if (Object.keys(userData).length) {
      authenticateUser()
    } else {
      setAuthenticated(false)
      setLoading(false)
    }
  }, [cookies, userData])

  return authenticated && !loading ? <Outlet /> : loading ? <Loader /> : <Navigate to="/connexion" />
}

export default PersistLogin