import { useState } from "react";
import styled from "styled-components";
import { getTranslate } from "../contexts/Utils";
import Categories from "../containers/Categories";
import Tools from "../containers/Tools";

const Settings = ({ lang }) => {
  const [currentView, setCurrentView] = useState(1);

  const GetCurrentRender = () => {
    switch (currentView) {
      case 1: return <Categories />;
      case 2: return <Tools />;
      default: return;
    }
  }

  return <S.Container>
    <h1>{getTranslate('settings', lang)}</h1>
    <S.SwitchContainer>
      <S.Switch active={currentView === 1} onClick={() => setCurrentView(1)}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M324.4 64C339.6 64 352 76.37 352 91.63C352 98.32 349.6 104.8 345.2 109.8L240 230V423.6C240 437.1 229.1 448 215.6 448C210.3 448 205.2 446.3 200.9 443.1L124.7 385.6C116.7 379.5 112 370.1 112 360V230L6.836 109.8C2.429 104.8 0 98.32 0 91.63C0 76.37 12.37 64 27.63 64H324.4zM144 224V360L208 408.3V223.1C208 220.1 209.4 216.4 211.1 213.5L314.7 95.1H37.26L140 213.5C142.6 216.4 143.1 220.1 143.1 223.1L144 224zM496 400C504.8 400 512 407.2 512 416C512 424.8 504.8 432 496 432H336C327.2 432 320 424.8 320 416C320 407.2 327.2 400 336 400H496zM320 256C320 247.2 327.2 240 336 240H496C504.8 240 512 247.2 512 256C512 264.8 504.8 272 496 272H336C327.2 272 320 264.8 320 256zM496 80C504.8 80 512 87.16 512 96C512 104.8 504.8 112 496 112H400C391.2 112 384 104.8 384 96C384 87.16 391.2 80 400 80H496z" /></svg>
        <span>{getTranslate('categories', lang)}</span>
      </S.Switch>
      <S.Switch active={currentView === 2} onClick={() => setCurrentView(2)}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M507.4 114.5c-2.25-9.5-9.626-17-19-19.63c-9.501-2.625-19.63 0-26.63 7L397.9 165.7l-44.25-7.375l-7.376-44.25l63.88-63.88c6.876-6.875 9.626-17.13 7.001-26.5c-2.625-9.5-10.25-16.88-19.75-19.25c-51.75-12.75-105.4 2-143.3 39.75C216.2 82.12 201.9 136.9 215.5 188.1l-193.3 193.3c-29.63 29.63-29.63 77.88 0 107.5C36.47 504.1 55.6 512 75.98 512c20.25 0 39.25-7.875 53.63-22.25l193.1-193.1c52.13 13.75 106.9-.75 144.9-38.88C505.5 219.1 520.4 166.4 507.4 114.5zM445 235.2c-31.75 31.75-78.38 42.63-121.8 28.13l-9.376-3.125L106.1 467.1c-16.63 16.63-45.5 16.63-62.13 0c-17.13-17.13-17.13-45.13 0-62.25l207-207L248.7 188.6c-14.38-43.5-3.625-90.13 28-121.8c22.75-22.63 52.75-34.88 83.76-34.88c6.876 0 13.88 .625 20.75 1.75l-69.26 69.38l13.75 83l83.13 13.88l69.26-69.38C484.9 168.9 472.8 207.5 445 235.2zM79.99 415.1c-8.876 0-16 7.125-16 16s7.125 16 16 16s16-7.125 16-16S88.86 415.1 79.99 415.1z" /></svg>
        <span>{getTranslate('additional_parameters', lang)}</span>
      </S.Switch>
    </S.SwitchContainer>
    <S.Content>
      <GetCurrentRender />
    </S.Content>
  </S.Container>
}

export default Settings;

const S = {}
S.Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: calc(100% - 55px);

  & > h1 {
    text-transform: uppercase;
    color: ${({ theme }) => theme.text};
    font-size: 23px;
    margin-bottom: 20px;
    font-weight: bold;

    & > span {
      color: ${({ theme }) => theme.title}
    }
  }
`

S.SwitchContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 30px;
`

S.Switch = styled.div`
  width: 55px;
  height: 55px;
  background: ${({ theme, active }) => active ? theme.title : '#fff'};
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;

  & > svg {
    fill: ${({ theme, active }) => active ? theme.textInverse : '#000'};
    width: 25px;
    height: 25px;
  }
  & > span {
    text-align: center;
    z-index: 100;
    opacity: 0;
    transition: .3s;
    position: absolute;
    top: calc(100% + 10px);
    background: ${({ theme }) => theme.card};
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 3px 8px;
    font-size: 13px;
    border-radius: 5px;
    padding: 5px 10px;

    @media(max-width: 1100px) {
      display: none;
    }
  }
  &:hover > span {
    opacity: 1;
  }
`

S.Content = styled.div`
  width: 100%;
  height: -webkit-fill-available;
  border-radius: 10px;
  overflow-y: auto;
`