import { Link } from "react-router-dom";
import styled from "styled-components";
import Cookies from "universal-cookie";
import { getTranslate } from "../contexts/Utils";

const Headband = ({ lang }) => {
  return
  const cookies = new Cookies();
  const user = cookies.get('nudge_user')

  const userConfirmed = user.role === 3 || ((user.role === 1 && Boolean(user.subscription_status)) || (user.role === 2 && Boolean(user.account_confirmed) && user.status === 2))
  let message = [];
  if (!userConfirmed) {
    // if (user.role === 1) message.push(getTranslate('please_confirm_subscription', lang))
    // if (user.role === 2 && !Boolean(user.account_confirmed)) message.push(getTranslate('please_confirm_stripe', lang))
    if (user.role === 2 && user.status === 1) message.push(getTranslate('waiting_for_validation', lang))
  }

  if (userConfirmed) return;
  return <S.Container to='/mon-compte?view=4'>
    {message.map((e, i) => <p key={i}>{e}</p>)}
  </S.Container>
}

export default Headband;

const S = {}
S.Container = styled(Link)`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: ${({ theme }) => theme.orange};
  padding: 20px;
  border-radius: 5px;
  box-sizing: border-box;
  margin-bottom: 30px;

  & p {
    margin: 0;
  }

  @media(max-width: 1100px) {
    width: calc(100% - 60px);
    font-size: 15px;
    padding: 15px;
  }
  @media(max-width: 750px) {
    width: calc(100% - 60px);
    font-size: 14px;
    padding: 15px;
  }
`