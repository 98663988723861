import React, { createContext, useState } from 'react';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import Router from "./contexts/Router";

export const Context = createContext();

const App = () => {
  const [theme, setTheme] = useState(localStorage.getItem('nudge_theme') || 'light');

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <Context.Provider value={{ theme, setTheme }}>
    <ThemeProvider theme={{ ...globalTheme, ...theme === 'dark' ? darkTheme : lightTheme }}>
      <GlobalStyle />
      <Router />
    </ThemeProvider>
  </Context.Provider>
}

export default App;

// 
// ───  STYLE DEFINITION ───────────────────────────────────────
//
const globalTheme = {
  black: '#585858'
}

const lightTheme = {
  background: '#F0F3F8',
  card: '#FFF',
  title: '#0FA2F4',
  linear: 'linear-gradient(96.15deg, #063AAE -15.93%, #2FCAFB 112.16%);',
  text: '#000',
  textInverse: '#fff',
  blue: 'rgba(0, 78, 145, 0.2)',
  lightBlue: 'rgb(0 78 145 / 4%)',
  inputBg: '#f0f0f0',
  logo: '#172749',
  red: 'linear-gradient(96.15deg,#973232 -15.93%,#f41919 112.16%)',
  orange: 'linear-gradient(96.15deg,#976d32 -15.93%,#f49419 112.16%)',
  colorScheme: 'light'
};

const darkTheme = {
  background: '#2A447C',
  card: '#172749',
  title: '#9DECBF',
  linear: 'linear-gradient(96.15deg, #368467 -15.93%, #1CF399 112.16%);',
  text: '#FFF',
  textInverse: '#000',
  blue: '#8FB1D4',
  lightBlue: '#8fb1d417',
  inputBg: '#fff',
  logo: '#fff',
  red: 'linear-gradient(96.15deg,#973232 -15.93%,#f41919 112.16%)',
  orange: 'linear-gradient(96.15deg,#976d32 -15.93%,#f49419 112.16%)',
  colorScheme: 'dark'
};

const GlobalStyle = createGlobalStyle`
  body {
    background: ${({ theme }) => theme.background};
    padding: 0;
    margin: 0;
    font-family: 'Poppins', sans-serif;

    * {
      &::-webkit-scrollbar {
        width: 7px;
        background-color: transparent;
      }
      
      &::-webkit-scrollbar-thumb {
        background-color: #fff;
        border-radius: 5px;
      }
      
      &::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: transparent;
      }
      
      input:-webkit-autofill,
      input:-webkit-autofill:hover, 
      input:-webkit-autofill:focus,
      input:-webkit-autofill:active {
        border-radius: unset !important;
        -webkit-text-fill-color: ${({ theme }) => theme.text} !important;
        -webkit-box-shadow: 0 0 0px 1000px transparent inset;
        transition: background-color 5000s ease-in-out 0s;
      }
    }

    a {
      text-decoration: none;
    }

    p, a, span {
      color: ${({ theme }) => theme.text}; 
    }

    #root {
      display: flex;
    }

    .App {
      display: flex;
      width: 100%;
    }

    .container {
      display: flex;
      flex-direction: column;
      padding: 20px 20px 20px 30px;
      box-sizing: border-box;
      width: calc(100% - 200px);
      margin-left: auto;

      &.expanded-menu {
        width: calc(100% - 50px);
      }
      
      @media(max-width: 1100px) {
        width: 100%;
      }
      @media(max-width: 650px) {
        padding: 13px;
      }
    }
  }
`;