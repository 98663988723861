import { forwardRef, useEffect, useState } from "react";
import styled from "styled-components";
import { getTranslate } from "../contexts/Utils";
import { TextField, Autocomplete } from '@mui/material';

const SelectAutocomplete = forwardRef((props, ref) => {
  const { lang, array, defaultValue, width = '-webkit-fill-available' } = props;
  const [value, setValue] = useState([]);

  const handleCatChange = (e, val) => {
    setValue(val);
    ref.current = val;
  }

  useEffect(() => {
    if (defaultValue) setValue(array.filter(e => defaultValue.includes(e.id)))
  }, [defaultValue, array])

  return <S.Container width={width} className='select-autocomplete'>
    <Autocomplete
      multiple
      options={array}
      onChange={handleCatChange}
      value={value}
      getOptionLabel={(option) => option.name}
      renderInput={(params) => (
        <TextField
          {...params}
          InputLabelProps={{ shrink: true }}
          label={getTranslate('technical_areas', lang)}
          placeholder={getTranslate('add', lang)}
        />
      )}
    />
  </S.Container>
});

export default SelectAutocomplete;

const S = {}
S.Container = styled.div`
  width: ${({ width }) => width};
`