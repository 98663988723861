// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Api from '../contexts/Api';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const StripeAccountValidation = () => {
  const [accountStatus, setAccountStatus] = useState();

  // 
  // ─── VALIDATE OR NOT STRIPE ACCOUNT ───────────────────────────────────────
  //
  useEffect(() => {
    const validateAccount = async () => {
      const subscriptionValidated = await Api({
        endpoint: '/stripe/accounts/validate'
      });
      setAccountStatus(subscriptionValidated.success);
    }
    validateAccount()
  }, [])

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  if (accountStatus) {
    return <p>Votre compte a été validé</p>
  } else if (accountStatus === false) {
    return <p>Votre compte n'est pas validé, veuillez le valider en cliquant <Link to="/mon-compte?view=4">ici</Link></p>
  }
};

export default StripeAccountValidation;