import { useEffect, useState } from 'react';
import styled from "styled-components";
import Cookies from "universal-cookie";
import Api from "../contexts/Api";
import SubscriptionExplanation from "../components/SubscriptionExplanation";

const Subscription = ({ lang }) => {
  const cookies = new Cookies();
  const userData = cookies.get('nudge_user');
  const [subscriptionType, setSubscriptionType] = useState('monthly');
  const [subscriptionPlan, setSubscriptionPlan] = useState([]);
  const [subscriptionStopped, setSubscriptionStopped] = useState(false);
  const [canceledSubscription, setCanceledSubscription] = useState(false);

  // 
  // ─── GET SUBSCRIPTIONS PLANS AND CURRENT SUBSCRIPTIONS ───────────────────────────────────────
  //
  useEffect(() => {
    const fetchData = async () => {
      const getSubscriptions = await Api({
        endpoint: '/stripe/subscriptions',
        method: "GET",
      })
      if (getSubscriptions.success) {
        if (getSubscriptions.plans) {
          setSubscriptionPlan(getSubscriptions.plans.sort((a, b) => a.plan_type > b.plan_type ? 1 : -1))
        } else if (getSubscriptions.current_plan) {
          setSubscriptionType(getSubscriptions.current_plan === 'month' ? 'monthly' : 'yearly');
          setSubscriptionStopped(true)

          if (getSubscriptions.canceled) {
            setCanceledSubscription(true)
          }
        }
      }
    }
    if (!subscriptionPlan.length) fetchData()
  }, [subscriptionPlan])

  // 
  // ─── GET LINK TO SUBSCRIBE ON ONE PLAN ───────────────────────────────────────
  //
  const handleSubscription = async (e) => {
    e.preventDefault();
    const stripeSubscription = await Api({
      endpoint: '/stripe/subscriptions',
      data: { subscription_type: subscriptionType }
    })
    window.location.href = stripeSubscription.url
  }

  // 
  // ─── HANDLE SUBSCRIPTION CANCEL ───────────────────────────────────────
  //
  const handleSubscriptionCancel = async () => {
    const cancelSubscription = await Api({ endpoint: '/stripe/subscriptions', method: 'DELETE' });

    if (cancelSubscription.success) {
      setCanceledSubscription(true)
    }
  }

  // 
  // ─── GET SUBSCRIPTION TYPE FROM CARDS ───────────────────────────────────────
  //
  const handleSubscriptionType = (e) => {
    setSubscriptionType(e)
  }

  return <S.Container>
    {subscriptionPlan.length ? <form id='subscription'>
      <S.SubscriptionContainer>
        {subscriptionPlan.map((plan) => {
          console.log(plan)
          return <SubscriptionExplanation type={plan.plan_type} is_active={subscriptionType === plan.plan_type} handleChange={handleSubscriptionType} />
        })}
      </S.SubscriptionContainer>
      <S.Button onClick={handleSubscription}>Souscrire</S.Button>
    </form> : subscriptionStopped && <div>
      <SubscriptionExplanation type={subscriptionType} is_active={true} />
      {!canceledSubscription && <S.Button onClick={handleSubscriptionCancel}>Annuler mon abonnement</S.Button>}
      {canceledSubscription && <S.CancelParagraphe>Abonnement annulé, vous pouvez en profiter jusqu'à la fin de la période.</S.CancelParagraphe>}
    </div>
    }
  </S.Container >
}

export default Subscription;

const S = {}
S.Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 30px;
  box-sizing: border-box;
  background: ${({ theme }) => theme.card};
  border-radius: 10px;
  overflow-y: auto;
  height: '100%';

  & h2 {
    color: ${({ theme }) => theme.title} !important;
    font-size: 21px;
    text-transform: uppercase;
    width: 100%;
    margin: 0;
    margin-bottom:20px
  }
`
S.Button = styled.button`
  margin-left: ${({ right }) => right ? 'auto' : 'unset'};
  background: ${({ theme }) => theme.linear};
  border: none;
  color: #fff !important;
  font-size: 16px;
  font-weight: bold;
  padding: 12px 28px;
  border-radius: 5px;
  width: fit-content;
  margin-top: 35px;
  cursor: pointer;
`

S.CancelSubscription = styled.p`
  margin:0;
`

S.SubscriptionContainer = styled.div`
  display:flex;
  gap:20px;
`

S.CancelParagraphe = styled.p`
  margin-bottom:0;
`