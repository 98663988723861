/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from "react";
import { createUseStyles } from 'react-jss';
import { getValue, formatDate } from '../contexts/Utils';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import ButtonGroup from "@mui/material/ButtonGroup";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import FormHelperText from '@mui/material/FormHelperText';
import DeleteIcon from '@mui/icons-material/Delete';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import IconButton from '@mui/material/IconButton';
import { Context } from '../App';

// 
// ─── TEXTFIELD ───────────────────────────────────────
//
export const Text = (opt) => {
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/gm;
  const field = opt.field;
  const { checkPassword = true } = field;
  const defaultValue = getValue(field, ['default']) || field.value || ''
  const dataSet = {};

  const [value, setValue] = useState(defaultValue)
  const [error, setError] = useState(false)
  const onLoad = useRef(true);
  const { theme } = useContext(Context);

  const handleChange = (e) => {
    setValue(e.target.value);
    if (field.handleChange) {
      field.handleChange(e.target.value)
    }
  };

  // 
  // ─── WAITS THAT USER STOPS TYPING ───────────────────────────────────────
  //
  useEffect(() => {
    // 
    // ─── HANDLE PASSWORD CHANGE ───────────────────────────────────────
    //
    const handlePasswordChange = async () => {
      // dont check the empty input on page load
      if (onLoad.current) return onLoad.current = false;

      if (value.match(passwordRegex)) return setError(false);
      setError(true);
    }

    if (field.type === 'password' && checkPassword) {
      const delay = setTimeout(() => {
        handlePasswordChange();
      }, 1000)

      return () => clearTimeout(delay)
    }
  }, [value])

  // 
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const inputStyle = {
    // input
    '.MuiInputBase-input': {
      paddingLeft: !field.multiline && 0,
      paddingBottom: !field.multiline && 0.5,
      padding: field.multiline && '10px',
      paddingTop: field.multiline && 0,
      borderBottom: field.multiline ? '' : '2px solid #8FB1D4',
    },
    // label
    '.MuiFormLabel-root': {
      transform: field.multiline ? 'translate(14px, -9px) scale(0.75)' : 'translate(0px, -9px) scale(0.75)',
    },
    // fieldset
    '.MuiOutlinedInput-notchedOutline': {
      border: !field.multiline && 'none !important',
    },
    '& textarea': {
      height: '120px !important',
    },
    "& .MuiInputBase-root": {
      padding: field.multiline && 0,
      paddingTop: field.multiline && '10px',
    }
  }

  const useStyle = createUseStyles({
    passwordError: (props) => ({
      fontSize: 13,
      fontWeight: 'lighter',
      color: props.theme === 'light' ? '#d32f2f !important' : '#fff',
      marginTop: 4,
      height: '0',
      opacity: 0,
      transition: '.3s',

      '&.display': {
        opacity: 1,
        height: 'auto',
        color: props.theme === 'light' ? '#d32f2f !important' : '#fff',
      }
    })
  })

  const classes = useStyle({ theme });

  useEffect(() => {
    if (value !== defaultValue) {
      setValue(defaultValue)
    }
  }, [defaultValue])

  Object.entries(field.dataSet || {}).map(([key, value]) => dataSet[key] = value);

  let textField = <TextField
    multiline={field.multiline}
    rows={field.rows || 1}
    InputLabelProps={{ shrink: true }}
    label={field.label}
    type={field.type}
    name={field.name}
    value={value}
    onChange={handleChange}
    inputProps={{
      ...dataSet,
      autoComplete: 'off',
    }}
    sx={inputStyle}
    error={field.error || error ? true : false}
    required={field.required}
    size="small"
    helperText={field.error}
    placeholder={field.placeholder}
    autoComplete='off'
  />
  return <>
    {textField}
    {field.type === "password" && <span className={`${classes.passwordError} ${error ? "display" : ""} password-error`}>Le mot de passe doit contenir au minimum 8 caractères, un chiffre, une majuscule et une minuscule</span>}
  </>
}

// 
// ─── SWITCH ───────────────────────────────────────
//
export const SwitchField = (opt) => {

  const field = opt.field
  const defaultValue = getValue(field, ['default']) !== undefined ? getValue(field, ['default']) : field.value || 0
  // 
  // ─── STATE DECLARATION ───────────────────────────────────────
  //
  const [selected, setSelected] = useState(defaultValue)

  useEffect(() => {
    if (defaultValue !== selected) {
      setSelected(defaultValue)
    }
  }, [defaultValue])

  // 
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    label: {
      color: 'var(--text-color)'
    }
  })
  const classes = useStyle();

  return (
    <>
      <FormControlLabel
        control={
          <Switch checked={selected ? true : false} value={selected} onChange={() => {
            let value = selected === 0 ? 1 : 0
            setSelected(value)
            if (field.handleChange) {
              field.handleChange(value)
            }
          }} name={field.name} />
        }
        className={classes.label}
        label={field.label || ''}
      />
    </>
  )
}

// 
// ─── RADIO ───────────────────────────────────────
//
export const RadioField = (opt) => {

  const field = opt.field
  const defaultValue = getValue(field, ['default']) !== undefined ? getValue(field, ['default']) : field.value || 0;

  const [value, setValue] = useState(defaultValue);

  const handleChange = (event) => {
    setValue(event.target.value);
    if (field.handleChange) {
      field.handleChange(event.target.value)
    }
  };

  // 
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    label: {
      color: 'var(--text-color)'
    }
  })

  const classes = useStyle();

  return <>
    <FormControl>
      <FormLabel id="demo-radio-buttons-group-label">{field.label}</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-radio-buttons-group-label"
        value={value}
        name={field.name}
        onChange={handleChange}
      >
        {field.values.map((radio) => (
          <FormControlLabel key={radio.label} value={radio.value} control={<Radio />} label={radio.label} className={classes.label} />
        ))}
      </RadioGroup>
    </FormControl>
  </>
}

// 
// ─── SELECT ───────────────────────────────────────
//
export const SelectField = (opt) => {
  const field = opt.field
  const defaults = getValue(field, ['default'])

  // Define the name and value of select items
  const dataLabel = field.dataLabel || 'name';
  const dataValue = field.dataValue || 'id'

  const [select, selectValue] = useState(defaults);

  const handleChange = (event) => {
    selectValue(event.target.value);
    if (field.handleChange) {
      field.handleChange(event.target.value)
    }
  };

  useEffect(() => {
    if (defaults && select !== defaults) {
      selectValue(defaults)
    }
  }, [defaults])

  return (
    <FormControl variant="outlined" size="small" sx={{ width: field.childSize ? field.childSize : '100%' }}>
      <InputLabel shrink>{field.label}</InputLabel>
      <Select
        value={select}
        input={
          <OutlinedInput
            notched
            name={field.name}
            label={field.label}
          />
        }
        onChange={handleChange}
      >
        {field.data.map((value) => (
          <MenuItem key={value[dataValue]} value={value[dataValue]}>{value[dataLabel]}</MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

// 
// ─── CHECKBOX ───────────────────────────────────────
//
export const CheckboxField = (opt) => {

  const field = opt.field
  // 
  // ─── STATE DECLARATION ───────────────────────────────────────
  //
  const [checked, setChecked] = useState(field.checked || false)

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  return <>
    <FormControlLabel control={<Checkbox checked={checked} required={field.required} onChange={handleChange} inputProps={{ 'aria-label': 'controlled' }} name={field.name} />} label={field.label} />
    {field.error && <FormHelperText error={true}>{field.error}</FormHelperText>}
  </>
}

// 
// ─── MULTIPLE ───────────────────────────────────────
//
export const Multiple = (opt) => {
  const field = opt.field
  const fieldName = field.name
  const titles = field.titles
  const defaults = getValue(field, ['default'])

  const componentValue = getValue(field, ['components', 'value'])
  const componentLabel = getValue(field, ['components', 'label'])
  const componentLabelType = getValue(field, ['components', 'label_type'])

  // 
  // ─── CHAMPS NECESSAIRES ───────────────────────────────────────
  //
  let multipleFields = {}

  if (defaults) {
    Object.values(JSON.parse(defaults)).forEach((el, index) => {
      const elementId = `${field.name}_${index}`
      multipleFields[elementId] = [
        { type: componentLabelType, component: componentLabel, label: titles.label, value: el.label, name: `${field.name}_label_${index}` },
        { type: "text", component: componentValue, label: titles.value, value: el.value, name: `${field.name}_value_${index}`, multiline: field.multiline, hidden: field.hidden }
      ]
    })
  } else {
    multipleFields = {
      1: [
        { type: componentLabelType, component: componentLabel, label: titles.label, name: `${fieldName}_label_1` },
        { type: "text", component: componentValue, label: titles.value, name: `${fieldName}_value_1`, multiline: field.multiline, hidden: field.hidden }
      ]
    }
  }

  // 
  // ─── STATE DECLARATION ───────────────────────────────────────
  //
  const [fields, setFields] = useState(multipleFields)

  // 
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    multipleContainer: {
      display: 'flex',
      flexDirection: 'column'
    },
    multipleLabel: {
      display: 'flex',
      '& > span': {
        width: '50%'
      }
    },
    multipleFields: {
      display: 'flex',
      marginTop: 20,
    },
    button: {
      marginTop: "20px !important"
    },
    inputLeft: {
      marginLeft: 5
    },
    inputRight: {
      marginRight: 5
    },
    label: {
      color: 'var(--text-color)'
    }
  })

  const classes = useStyle();

  return (
    <div>
      <label className={classes.label}>{field.label}</label>
      <div className={classes.multipleContainer + ' form__multiple'}>
        {Object.values(fields).map((element, index) => {
          return <div key={index} className={classes.multipleFields}>
            {
              element.map((el, i) => {
                return <Formfields key={i} field={{ ...el, ...{ dataSet: { 'data-attribute': 'multiple', 'data-name': fieldName } } }} classNameOuter={i === 0 ? classes.inputRight : classes.inputLeft} />
              })
            }
          </div>
        })}
      </div>
      {!field.removeAdd && <Button className={classes.button} variant="contained" onClick={(event) => {
        event.preventDefault();
        let index = Object.keys(fields).length + 1;

        // 
        // ─── AJOUT D'UN ELEMENT SUPPLEMENTAIRE A LA LISTE ───────────────────────────────────────
        //
        setFields(fields[index] = {
          ...fields,
          [`${field.name}_${index}`]: [
            { type: componentLabelType, component: componentLabel, label: titles.label, name: `${fieldName}_label_${index}` },
            { type: "text", component: componentValue, label: titles.value, name: `${fieldName}_value_${index}`, multiline: field.multiline, hidden: field.hidden }
          ]
        })
      }}>Ajouter</Button>}
    </div>
  )
}

export const UploadFile = (opt) => {
  const field = opt.field;
  const [file, setFile] = useState(field.default || '')

  // 
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  let useStyle = createUseStyles({
    input: { display: "none !important" },
    label: {
      marginBottom: 10,
      display: 'block',
      color: 'var(--text-color)'
    },
    error: {
      color: '#d32f2f',
      marginTop: 5,
      display: 'block'
    },
    field: {
      width: '100%',
      border: field.error ? '1px solid #d32f2f !important' : 'initial'
    },
    fileUploaded: {
      width: 'calc(50% - 10px)',
      alignItems: 'center'
    },
    fileContent: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: 20,
      marginRight: 'auto'
    },
    fileIcon: {
      fontSize: '35px !important',
      color: 'var(--primary-color)'
    },
    fileLabel: {
      marginBottom: 5
    },
    fileName: {
      fontSize: 13,
      width: 275,
      textOverflow: 'ellipsis',
      overflow: 'hidden'
    }
  })

  const classes = useStyle();

  const handleChange = (e) => {
    setFile(e.target.files[0].name)
    if (field.handleChange) {
      field.handleChange(e)
    }
  }

  return <>
    <div className={classes.fileUploaded} style={{ display: file ? 'flex' : 'none', }}>
      <InsertDriveFileIcon className={classes.fileIcon} />
      <div className={classes.fileContent}>
        <span className={classes.fileLabel}>{field.label}</span>
        <span className={classes.fileName}>{file}</span>
      </div>
      <IconButton aria-label="delete" onClick={() => {
        document.querySelector(`input[name='${field.name}']`).value = '';
        setFile(null);
        if (field.handleRemove) {
          field.handleRemove({ name: field.name, filename: field.default })
        }
      }}>
        <DeleteIcon />
      </IconButton>
    </div>
    <div className={classes.fileContainer} style={{ display: file ? 'none' : 'block' }}>
      <span className={classes.label}>{opt.field.label}</span>
      <label htmlFor={`${field.name}-file`} className={classes.label}>
        <Input className={classes.input} accept="image/*" id={`${field.name}-file`} type="file" name={field.name} onChange={(e) => { handleChange(e) }} required={field.required ? true : false} />
        <Button variant="contained" component="span" className={classes.field}>Télécharger</Button>
        {field.error && <span className={classes.error}>{field.error}</span>}
      </label>
    </div>
  </>
}

// 
// ─── DATE AND TIME PICKER ───────────────────────────────────────
//
const DatePicker = (props) => {
  let field = props.field;

  let defaultDate = field.default;
  const dataSet = {};

  if (field.isoDefault || (field.filled && field.default)) {
    defaultDate = field.default
  } else {
    defaultDate = formatDate({ time: field.default, display: 'db' }) || null
  }

  Object.entries(field.dataSet || {}).map(([key, value]) => dataSet[key] = value);

  const [date, setDate] = useState(defaultDate);
  var now = new Date();
  now.setMinutes(now.getMinutes() - now.getTimezoneOffset());

  return <TextField
    label={field.label}
    type={field.type}
    size="small"
    value={field.value || date}
    // defaultValue={field.filled ? now.toISOString().slice(0, 16) : field.default || ''}
    name={field.name}
    required={field.required}
    error={field.error ? true : false}
    helperText={field.error}
    inputProps={{
      ...dataSet
    }}
    onChange={(e) => {
      setDate(e.target.value)
      if (field.handleChange) {
        field.handleChange(e.target.value)
      }
    }}
    InputLabelProps={{
      shrink: true,
    }}
  />
}

// 
// ─── BUTTON GROUP ───────────────────────────────────────
//
const GroupedButtons = (opt) => {
  const field = opt.field;
  const [counter, setCounter] = useState(1);

  // 
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    label: {
      marginRight: 10,
    }
  })

  const classes = useStyle();

  // 
  // ─── HANDLE INPUT INCREMENTATION OR DECREMENT ───────────────────────────────────────
  //
  const handleIncrement = () => {
    setCounter(counter + 1);
  };

  const handleDecrement = () => {
    setCounter(counter - 1);
  };
  return (
    <div>
      <span className={classes.label}>{field.label}</span>
      <ButtonGroup size="small" aria-label="small outlined button group">
        <Button disabled={counter === 1} onClick={handleDecrement}>-</Button>
        <TextField
          size="small"
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          name={field.name}
          value={counter} onChange={(e) => {
            const value = parseInt(e.target.value);
            if (value <= 8) {
              setCounter(parseInt(e.target.value))
            }
          }}
          style={{ width: 40 }}
        />
        <Button disabled={counter === 8} onClick={handleIncrement}>+</Button>
      </ButtonGroup>
    </div>
  );
}
//
// ─── GENERATION DES CHAMPS ───────────────────────────────────────
//
const SearchField = (props) => {
  const field = props.field;
  const data = field.data;

  const defaultValue = getValue(field, ['default']) || field.value || ''
  const [value, setValue] = useState(field.multiple ? defaultValue.split(',').filter(v => v) || [] : defaultValue || '');

  useEffect(() => {
    if (field.default !== value) {
      setValue(field.multiple && field.default ? field.default.split(',').filter(v => v) || [] : field.default || '')
    }
  }, [field.default])

  let multipleValue

  if (value && value.length) {
    multipleValue = value.map((val) => {
      if (val.id) {
        return val.id
      } else {
        return val
      }
    })
  }

  return <>
    <Autocomplete
      id={props.name}
      size="small"
      freeSolo
      multiple={field.multiple}
      options={data}
      noOptionsText={'Aucun résultat'}
      getOptionLabel={(option) => option.label || option || ''}
      onChange={(e, inputValue) => {
        setValue(inputValue)
      }}
      defaultValue={value}
      className={props.className}
      renderInput={(params) =>
        <TextField
          {...params}
          InputLabelProps={{ shrink: true }}
          label={field.label}
          value={field.default}
          placeholder={field.placeholder}
        />
      }
    />
    {value && <input type='hidden' name={field.name} value={field.multiple && multipleValue ? multipleValue.join(',') : value.id} />}
  </>
}

// 
// ─── GENERATION DES CHAMPS ───────────────────────────────────────
//
const Formfields = (props) => {
  let fields

  switch (props.field.component) {
    case "switch":
      fields = SwitchField(props)
      break;
    case "radio":
      fields = RadioField(props)
      break;
    case "select":
      fields = SelectField(props)
      break;
    case "checkbox":
      fields = CheckboxField(props)
      break;
    case "multiple":
      fields = Multiple(props)
      break;
    case "file":
      fields = UploadFile(props)
      break;
    case "datepicker":
      fields = DatePicker(props);
      break;
    case "grouped_btn":
      fields = GroupedButtons(props);
      break;
    case "search":
      fields = SearchField(props);
      break;
    default:
      fields = Text(props)
      break;
  }

  // 
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    inputContainer: {
      display: props.field.hidden ? 'none' : "flex",
      flexDirection: "column",
      "@media screen and (max-width:780px)": {
        width: '100% !important'
      }
    }
  })

  const classes = useStyle();
  return <div className={classes.inputContainer + ' formfield_container ' + props.classNameOuter} style={{ width: props.field.size || (props.field.component !== 'color' ? '100%' : 'null'), maxWidth: props.field.maxWidth || 'unset' }}>
    {fields}
  </div>
}

export default Formfields