// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import { useState, forwardRef } from 'react';
import styled from 'styled-components';
import { getTranslate, prepareFormData, getValue } from '../contexts/Utils';
import Formfields from '../components/Formfields';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const Steps = forwardRef((props, ref) => {
  const { fields, handleSubmit, lang, errors, setErrors } = props;

  const [index, setIndex] = useState(0);
  const [data, setData] = useState({});

  // 
  // ─── HANDLE FIELDS CHANGE ───────────────────────────────────────
  //
  const handleChange = async () => {
    const newData = prepareFormData({ formId: 'registerForm' });
    if (newData.errors) return setErrors(newData.errors);

    setData({ ...data, ...newData.fields });

    // confirm
    if (index === fields.length - 1) {
      handleSubmit({ ...data, ...newData.fields });
      return;
    }

    // next
    setErrors({});
    setIndex(index + 1);
  }

  const handlePrevious = () => setIndex(index - 1);

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <S.Container>
    <S.Form name="registerForm">
      {fields[index].map((e, i) => {
        if (e.hasOwnProperty('cond') && !e.cond) return true
        if (e.custom_component) return e.custom_component
        if (Array.isArray(e)) {
          return <S.InnerFields key={i}>
            {e.map(innerField => {
              if (innerField.hasOwnProperty('cond') && !innerField.cond) return true
              return <Formfields key={innerField.name} field={{ ...innerField, shrink: true, size: 'calc(50% - 10px)', error: getValue(errors, [innerField.name, 'message']), default: innerField.component === 'select' ? data[innerField.name] || 1 : data[innerField.name] }} />
            })}
          </S.InnerFields>
        } else {
          return <Formfields key={e.name} field={{ ...e, shrink: true, size: 'calc(50% - 10px)', error: getValue(errors, [e.name, 'message']), default: e.component === 'select' ? data[e.name] || 1 : data[e.name] }} />;
        }
      })}
    </S.Form>
    <S.ButtonsContainer>
      {index !== 0 && <S.Button onClick={handlePrevious}>{getTranslate('previous', lang)}</S.Button>}
      <S.Button right={true} onClick={handleChange}>{getTranslate(index === fields.length - 1 ? "signup" : "next", lang)}</S.Button>
    </S.ButtonsContainer>
  </S.Container>
});

export default Steps;

// 
// ───  STYLE DEFINITION ───────────────────────────────────────
//
const S = {}
S.Container = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.background};
  width: 100%;
  padding: 30px;
  box-sizing: border-box;
  border-radius: 10px;
  height: fit-content;

  & *:not(.Mui-error, button, .password-error) {
    color: ${({ theme }) => theme.text} !important;
    border-color: ${({ theme }) => theme.text} !important;
  }
  & label.Mui-error {
    color: #d32f2f !important;
  }
  & div.Mui-error > input, div.Mui-error > textarea {
    border-color: #d32f2f !important;
  }
`

S.Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;

  & .select-autocomplete {
    @media(max-width: 780px) {
      width: 100%;
    }
  }
`

S.InnerFields = styled.div`
  width: 100%;
  gap: 20px;
  display: flex;
  flex-wrap: wrap;
`

S.ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

S.Button = styled.button`
  margin-left: ${({ right }) => right ? 'auto' : 'unset'};
  background: ${({ theme }) => theme.linear};
  border: none;
  color: #fff !important;
  font-size: 16px;
  font-weight: bold;
  padding: 12px 28px;
  border-radius: 5px;
  width: fit-content;
  margin-top: 35px;
  cursor: pointer;
`