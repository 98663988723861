// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import React from "react";
import { Route, Routes } from 'react-router-dom';
import Account from "../views/Account";
import NotFound from '../views/NotFound';
import Login from "../views/Login";
import RequireAuth from "../components/RequireAuth";
import Layout from "../components/Layout";
import Register from "../views/Register";
import Missions from "../views/Missions";
import Users from "../views/Users";
import Cookies from "universal-cookie";
import CreateMission from "../views/CreateMission";
import Informations from "../containers/Informations";
import Settings from "../views/Settings";
import StripeSubscriptionValidation from "../views/StripeSubscriptionValidation";
import StripeAccountValidation from "../views/StripeAccountValidation";
import ForgotPassword from "../views/ForgotPassword";
import ResetPassword from "../views/ResetPassword";
import Transactions from "../views/Transactions";
import CGU from "../views/CGU";
import CGV from "../views/CGV";
import LegalNotice from "../views/LegalNotice";

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const Router = () => {
  const cookies = new Cookies();
  const userData = cookies.get('nudge_user') || {};

  const routes = [
    { path: '/', deletedAllowed: true, component: userData.deleted ? <Missions history={true} status={[6, 7]} /> : <Missions status={[1]} /> },
    { path: '/missions', component: <Missions status={[2, 3, 4, 5]} /> },
    { path: '/missions/historique', component: <Missions history={true} status={[6, 7]} /> },
    { path: '/missions/recherche/:uuid', deletedAllowed: true, component: <Missions search={true} /> },
    { path: '/missions/creation', component: <CreateMission />, role: [1] },
    { path: '/missions/editer/:uuid', component: <CreateMission edit={true} />, role: [1, 3] },
    { path: '/utilisateurs', component: <Users />, role: [3] },
    { path: '/utilisateurs/editer/:uuid', component: <Informations />, role: [3] },
    { path: '/mon-compte', component: <Account /> },
    { path: '/parametres', component: <Settings />, role: [3] },
    { path: '/abonnement-validation', component: <StripeSubscriptionValidation />, role: [1] },
    { path: '/compte-validation', component: <StripeAccountValidation />, role: [2] },
    { path: '/factures', component: <Transactions /> },
  ]

  //
  // ─── DECLARATION OF ALL NECESSARY ROUTES ───────────────────────────────────────
  //
  return <Routes>
    <Route path="/connexion" element={<Login />} />
    <Route path="/cgu" element={<CGU />} />
    <Route path="/cgv" element={<CGV />} />
    <Route path="/mentions-legales" element={<LegalNotice />} />
    <Route path="/inscription" element={<Register />} />
    <Route path="/mot-de-passe-oublie" element={<ForgotPassword />} />
    <Route path="/reinitialiser-le-mot-de-passe/:token" element={<ResetPassword />} />
    <Route element={<RequireAuth />}>
      <Route path="/" element={<Layout />}>
        {routes.map((route) => {
          // role check
          const roleAllowed = !route.hasOwnProperty('role') || (route.role && route.role.includes(parseInt(userData.role)));
          // deleted user check
          const isDeleted = Boolean(userData.deleted);
          const userIsAllowed = Boolean(!isDeleted || (!route.hasOwnProperty('deletedAllowed') && !isDeleted) || (route.deletedAllowed && isDeleted));

          if (roleAllowed && userIsAllowed) return <Route key={route.path} path={route.path} element={route.component} />
          return null;
        })}
      </Route>
    </Route>
    <Route path="*" element={<NotFound />} />
  </Routes >
};

export default Router