import { useState } from "react";
import styled from "styled-components";
import Cookies from "universal-cookie";
import Analytics from "../containers/Analytics";
import Reviews from "../containers/Reviews";
import Informations from "../containers/Informations";
import Subscription from "../containers/Subscription";
import StripeAccount from "../containers/StripeAccount";
import { getTranslate } from "../contexts/Utils";

const Account = ({ lang }) => {
  const cookies = new Cookies();
  const userData = cookies.get('nudge_user')
  const role = userData.role;
  const query = new URLSearchParams(window.location.search);
  let queryView = parseInt(query.get('view')) || 1

  const [currentView, setCurrentView] = useState(queryView);

  const GetCurrentRender = () => {
    switch (currentView) {
      case 1: return <Informations />;
      case 2: return <Analytics />;
      case 3: return <Reviews />;
      case 4:
        if (role === 1) {
          return <Subscription />
        } else if (role === 2) {
          return <StripeAccount />
        }
        break;
      default: return <Informations />;
    }
  }

  return <S.Container>
    <h1 dangerouslySetInnerHTML={{ __html: getTranslate('my_account_colors', lang) }}></h1>
    <S.SwitchContainer>
      <S.Switch active={currentView === 1} onClick={() => setCurrentView(1)}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 480c-123.5 0-224-100.5-224-224s100.5-224 224-224s224 100.5 224 224S379.5 480 256 480zM256 184c13.25 0 24-10.74 24-24c0-13.25-10.75-24-24-24S232 146.7 232 160C232 173.3 242.7 184 256 184zM304 352h-32V240C272 231.2 264.8 224 256 224H224C215.2 224 208 231.2 208 240S215.2 256 224 256h16v96h-32C199.2 352 192 359.2 192 368C192 376.8 199.2 384 208 384h96c8.836 0 16-7.164 16-16C320 359.2 312.8 352 304 352z" /></svg>
        <span>Informations</span>
      </S.Switch>
      {role !== 3 &&
        <S.Switch active={currentView === 2} onClick={() => setCurrentView(2)}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M240 32C266.5 32 288 53.49 288 80V432C288 458.5 266.5 480 240 480H208C181.5 480 160 458.5 160 432V80C160 53.49 181.5 32 208 32H240zM240 64H208C199.2 64 192 71.16 192 80V432C192 440.8 199.2 448 208 448H240C248.8 448 256 440.8 256 432V80C256 71.16 248.8 64 240 64zM80 224C106.5 224 128 245.5 128 272V432C128 458.5 106.5 480 80 480H48C21.49 480 0 458.5 0 432V272C0 245.5 21.49 224 48 224H80zM80 256H48C39.16 256 32 263.2 32 272V432C32 440.8 39.16 448 48 448H80C88.84 448 96 440.8 96 432V272C96 263.2 88.84 256 80 256zM320 144C320 117.5 341.5 96 368 96H400C426.5 96 448 117.5 448 144V432C448 458.5 426.5 480 400 480H368C341.5 480 320 458.5 320 432V144zM352 144V432C352 440.8 359.2 448 368 448H400C408.8 448 416 440.8 416 432V144C416 135.2 408.8 128 400 128H368C359.2 128 352 135.2 352 144z" /></svg>
          <span>Analytics</span>
        </S.Switch>
      }
      {role === 2 &&
        <S.Switch active={currentView === 3} onClick={() => setCurrentView(3)}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M192 416c0-17.7-14.3-32-32-32H64c-17.7 0-32-14.3-32-32V64c0-17.7 14.3-32 32-32H448c17.7 0 32 14.3 32 32V352c0 17.7-14.3 32-32 32H309.3c-6.9 0-13.7 2.2-19.2 6.4L192 464V416zM64 0C28.7 0 0 28.7 0 64V352c0 35.3 28.7 64 64 64h64 32v32 48c0 6.1 3.4 11.6 8.8 14.3s11.9 2.1 16.8-1.5L309.3 416H448c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64H64zM176 189.3c0-16.2 13.1-29.3 29.3-29.3c7.8 0 15.2 3.1 20.7 8.6l18.7 18.7c6.2 6.2 16.4 6.2 22.6 0l18.7-18.7c5.5-5.5 12.9-8.6 20.7-8.6c16.2 0 29.3 13.1 29.3 29.3c0 7.8-3.1 15.2-8.6 20.7L256 281.4l-71.4-71.4c-5.5-5.5-8.6-12.9-8.6-20.7zM205.3 128c-33.8 0-61.3 27.4-61.3 61.3c0 16.2 6.5 31.8 17.9 43.3l82.7 82.7c6.2 6.2 16.4 6.2 22.6 0l82.7-82.7c11.5-11.5 17.9-27.1 17.9-43.3c0-33.8-27.4-61.3-61.3-61.3c-16.2 0-31.8 6.5-43.3 17.9l-7.4 7.4-7.4-7.4c-11.5-11.5-27.1-17.9-43.3-17.9z" /></svg>
          <span>{getTranslate('reviews', lang)}</span>
        </S.Switch>
      }
      {![2, 3].includes(role) &&
        <S.Switch active={currentView === 4} onClick={() => setCurrentView(4)}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M464 32C472.8 32 480 39.16 480 48C480 56.84 472.8 64 464 64H80C53.49 64 32 85.49 32 112V400C32 426.5 53.49 448 80 448H432C458.5 448 480 426.5 480 400V176C480 149.5 458.5 128 432 128H112C103.2 128 96 120.8 96 112C96 103.2 103.2 96 112 96H432C476.2 96 512 131.8 512 176V400C512 444.2 476.2 480 432 480H80C35.82 480 0 444.2 0 400V112C0 67.82 35.82 32 80 32H464zM360 288C360 274.7 370.7 264 384 264C397.3 264 408 274.7 408 288C408 301.3 397.3 312 384 312C370.7 312 360 301.3 360 288z" /></svg>
          <span>{getTranslate('subscription', lang)}</span>
        </S.Switch>
      }
      {![1, 3].includes(role) &&
        <S.Switch active={currentView === 4} onClick={() => setCurrentView(4)}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M492.4 220.8c-8.9 0-18.7 6.7-18.7 22.7h36.7c0-16-9.3-22.7-18-22.7zM375 223.4c-8.2 0-13.3 2.9-17 7l.2 52.8c3.5 3.7 8.5 6.7 16.8 6.7 13.1 0 21.9-14.3 21.9-33.4 0-18.6-9-33.2-21.9-33.1zM528 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h480c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zM122.2 281.1c0 25.6-20.3 40.1-49.9 40.3-12.2 0-25.6-2.4-38.8-8.1v-33.9c12 6.4 27.1 11.3 38.9 11.3 7.9 0 13.6-2.1 13.6-8.7 0-17-54-10.6-54-49.9 0-25.2 19.2-40.2 48-40.2 11.8 0 23.5 1.8 35.3 6.5v33.4c-10.8-5.8-24.5-9.1-35.3-9.1-7.5 0-12.1 2.2-12.1 7.7 0 16 54.3 8.4 54.3 50.7zm68.8-56.6h-27V275c0 20.9 22.5 14.4 27 12.6v28.9c-4.7 2.6-13.3 4.7-24.9 4.7-21.1 0-36.9-15.5-36.9-36.5l.2-113.9 34.7-7.4v30.8H191zm74 2.4c-4.5-1.5-18.7-3.6-27.1 7.4v84.4h-35.5V194.2h30.7l2.2 10.5c8.3-15.3 24.9-12.2 29.6-10.5h.1zm44.1 91.8h-35.7V194.2h35.7zm0-142.9l-35.7 7.6v-28.9l35.7-7.6zm74.1 145.5c-12.4 0-20-5.3-25.1-9l-.1 40.2-35.5 7.5V194.2h31.3l1.8 8.8c4.9-4.5 13.9-11.1 27.8-11.1 24.9 0 48.4 22.5 48.4 63.8 0 45.1-23.2 65.5-48.6 65.6zm160.4-51.5h-69.5c1.6 16.6 13.8 21.5 27.6 21.5 14.1 0 25.2-3 34.9-7.9V312c-9.7 5.3-22.4 9.2-39.4 9.2-34.6 0-58.8-21.7-58.8-64.5 0-36.2 20.5-64.9 54.3-64.9 33.7 0 51.3 28.7 51.3 65.1 0 3.5-.3 10.9-.4 12.9z" /></svg>
          <span>Stripe</span>
        </S.Switch>
      }
    </S.SwitchContainer>
    <S.Content>
      <GetCurrentRender />
    </S.Content>
  </S.Container>
}

export default Account;

const S = {}
S.Container = styled.div`
  display: flex;
  flex-direction: column;

  & > h1 {
    text-transform: uppercase;
    color: ${({ theme }) => theme.text};
    font-size: 23px;
    margin-bottom: 20px;
    font-weight: bold;

    & > span {
      color: ${({ theme }) => theme.title}
    }
  }
`

S.SwitchContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 30px;
`

S.Switch = styled.div`
  width: 55px;
  height: 55px;
  background: ${({ theme, active }) => active ? theme.title : '#fff'};
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;

  & > svg {
    fill: ${({ theme, active }) => active ? theme.textInverse : '#000'};
    width: 25px;
    height: 25px;
  }
  & > span {
    text-align: center;
    z-index: 100;
    opacity: 0;
    transition: .3s;
    position: absolute;
    top: calc(100% + 10px);
    background: ${({ theme }) => theme.card};
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 3px 8px;
    font-size: 13px;
    border-radius: 5px;
    padding: 5px 10px;

    @media(max-width: 1100px) {
      display: none;
    }
  }
  &:hover > span {
    opacity: 1;
  }
`

S.Content = styled.div`
  width: 100%;
  height: -webkit-fill-available;
  border-radius: 10px;
  overflow-y: auto;
`