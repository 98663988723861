import { useEffect, useState } from "react";
import styled from "styled-components";
import Cookies from "universal-cookie";
import { getTranslate, formatDate } from "../contexts/Utils";
import Api from '../contexts/Api';
import Stars from "../components/Stars";

const Reviews = ({ lang }) => {
  const cookies = new Cookies();
  const userData = cookies.get('nudge_user');
  const [reviews, setReviews] = useState([]);

  // 
  // ─── GET USER'S REVIEWS ───────────────────────────────────────
  //
  useEffect(() => {
    const getUsersInfo = async () => {
      const params = {
        query: { expert_id: userData.user_id },
        endpoint: '/reviews',
        method: 'GET'
      }
      const reviewsQuery = await Api(params);
      reviewsQuery.success && setReviews(reviewsQuery.data);
    }

    getUsersInfo();
  }, [userData.user_id])

  // 
  // ─── GEN AVERAGE SCORE ───────────────────────────────────────
  //
  const genAverage = () => {
    return (reviews.reduce((total, e) => total + e.score, 0) / reviews.length).toFixed(2);
  }

  // 
  // ─── RENDER ───────────────────────────────────────
  //
  return <S.Container>
    <h2>{getTranslate('my_feedback', lang)}</h2>
    {reviews.length ? <>
      <h3>{getTranslate('my_average_score', lang)}<span>{genAverage()} <Stars score={genAverage()} /></span></h3>
      <S.Columns>
        <span className="name">{getTranslate('name', lang)}</span>
        <span className="score">Score</span>
        <span className="comment">{getTranslate('comment', lang)}</span>
        <span className="date">Date</span>
      </S.Columns>
      {reviews.map((e, i) => <S.Card key={i}>
        <span className="name">{e.user_infos}</span>
        <span className="score"><Stars score={e.score} /></span>
        <span className="comment">{e.comment}</span>
        <span className="date">{formatDate({ time: e.time_create, display: 'date' })}</span>
      </S.Card>)}
    </>
      :
      <p>{getTranslate('no_feedback', lang)}</p>
    }
  </S.Container>
}

export default Reviews;

// 
// ─── STYLE DEFINITION ───────────────────────────────────────
//
const S = {}
S.Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  & > h2 {
    color: ${({ theme }) => theme.text};
    text-transform: uppercase;
    font-size: 24px;
    margin: 0;
    margin-bottom: 10px;
  }
  & > h3 {
    color: ${({ theme }) => theme.title};
    text-transform: uppercase;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    background: ${({ theme }) => theme.card};
    width: 260px;
    padding: 20px;
    border-radius: 10px;
    gap: 5px;
    white-space: nowrap;

    & > span {
      font-size: 24px;
      display: flex;
      align-items: center;
      gap: 15px;
    }
  }
`

S.Columns = styled.div`
  display: flex;
  padding: 0 20px;
  gap: 20px;
  box-sizing: border-box;

  & > .name {
    width: 150px;
  }
  & > .score {
    width: 90px;
  }
  & > .date {
    margin-left: auto;
    width: 70px;
  }

  @media(max-width: 800px) {
    display: none;
  }
`

S.Card = styled.div`
  display: flex;
  background: ${({ theme }) => theme.card};
  color: ${({ theme }) => theme.text};
  padding: 20px;
  border-radius: 10px;
  box-sizing: border-box;
  width: 100%;
  gap: 20px;
  font-size: 16px;
  font-weight: lighter;

  & > .name {
    min-width: 150px;
    max-width: 150px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  & > .score {
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  & > .comment {
    margin-left: 91px;
  }
  & > .date {
    color: #B4B4B4;
    font-size: 14px;
    margin-left: auto;
    width: 70px;
  }

  @media(max-width: 800px) {
    flex-wrap: wrap;
    
    & > .comment {
      margin-left: unset;
      order: 4;
      width: 100%;
    }
    & > .date {
      order: 3;
    }
  }
`