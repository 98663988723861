import { useState } from "react";
import styled from "styled-components";
import Checkbox from '@mui/material/Checkbox';
import FormHelperText from '@mui/material/FormHelperText';
import CGU from "../views/CGU";

const CGUCheck = (props) => {
  const { error } = props;
  const [checked, setChecked] = useState(false);
  const [modalActive, setModalActive] = useState(false);

  const handleChange = () => {
    setChecked(prev => !prev);
  }

  const handleModal = () => {
    setModalActive(prev => {
      document.body.style.overflow = prev ? 'unset' : 'hidden';
      return !prev
    })
  }

  const Modal = () => <S.Modal>
    <div onClick={handleModal}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M315.3 411.3c-6.253 6.253-16.37 6.253-22.63 0L160 278.6l-132.7 132.7c-6.253 6.253-16.37 6.253-22.63 0c-6.253-6.253-6.253-16.37 0-22.63L137.4 256L4.69 123.3c-6.253-6.253-6.253-16.37 0-22.63c6.253-6.253 16.37-6.253 22.63 0L160 233.4l132.7-132.7c6.253-6.253 16.37-6.253 22.63 0c6.253 6.253 6.253 16.37 0 22.63L182.6 256l132.7 132.7C321.6 394.9 321.6 405.1 315.3 411.3z" /></svg>
    </div>
    <CGU />
  </S.Modal>

  return <S.Container>
    <div>
      <Checkbox checked={checked} required={true} onChange={handleChange} inputProps={{ 'aria-label': 'controlled' }} name="cgu" />
      <label>J'accepte les <span className="cgu-link" onClick={handleModal}>conditions générales d'utilisation</span></label>
    </div>
    {error && <FormHelperText error={true}>{error}</FormHelperText>}
    {modalActive && <>
      <Modal />
      <S.Background onClick={handleModal} />
    </>}
  </S.Container>
}

export default CGUCheck;

const S = {}
S.Container = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  width: 100%;
  display: flex;

  & .cgu-link {
    cursor: pointer;
    color: ${({ theme }) => theme.title} !important;
  }

  & > div:first-child {
    width: fit-content;
  }
`

S.Modal = styled.div`
  z-index: 100;
  position: fixed;
  width: 90%;
  height: 90%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: ${({ theme }) => theme.card};
  overflow-y: scroll;
  border-radius: 5px;

  & > div:first-child {
    width: 100%;
    padding-top: 20px;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    padding-right: 30px;
    box-sizing: border-box;

    & svg {
      fill: ${({ theme }) => theme.text};
      height: 25px;
    }
  }
`

S.Background = styled.div`
  inset: 0;
  position: fixed;
  background: #000000a3;
`