import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";
import Cookies from "universal-cookie";
import { getTranslate } from "../contexts/Utils";
import Api from '../contexts/Api';

const Header = ({ lang }) => {
  const navigate = useNavigate();
  const cookies = new Cookies();
  const user = cookies.get('nudge_user');
  const role = user.role;
  const [searchActive, setSearchActive] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [focusActive, setFocusActive] = useState(false);
  const [missions, setMissions] = useState({});
  const industrialConfirmed = role === 1 && [1, 2].includes(user.subscription_status);;

  // 
  // ─── WAITS THAT USER STOPS TYPING ───────────────────────────────────────
  //
  useEffect(() => {
    // 
    // ─── HANDLE SEARCH CHANGE ───────────────────────────────────────
    //
    const handleSearchChange = async () => {
      if (!searchValue) return setMissions({});

      let query = { title: searchValue, fields: ['title, mission_id'], search: true, role };
      // hide deleted if not super-admin
      if (role !== 3) query.deleted = 0;
      // all missions created by pro if logged user is pro
      if (role === 1) query.user_create = user.user_id;
      // a deleted user can only see his history
      if (user.deleted) query.status = [6, 7];

      const params = {
        query,
        endpoint: '/missions',
        method: 'GET'
      }
      const missionsQuery = await Api(params);

      if (missionsQuery.data) return setMissions(missionsQuery.data)
      setMissions({});
    }

    const delay = setTimeout(() => {
      handleSearchChange();
    }, 300)

    return () => clearTimeout(delay)
  }, [searchValue, role, user.user_id, user.deleted])

  // 
  // ─── HANDLE RESULT CLICK ───────────────────────────────────────
  //
  const handleResultClick = (id) => {
    setSearchValue('');

    // display this mission
    navigate(`/missions/recherche/${id}`)
  }

  // 
  // ─── HANDLE SEARCH FOCUS ───────────────────────────────────────
  //
  const handleFocus = (val) => {
    if (!val) {
      setTimeout(() => {
        setFocusActive(val);
      }, 150);
      return
    }

    setFocusActive(val);
  }

  // 
  // ─── DISPLAY SEARCH BAR ───────────────────────────────────────
  //
  const displaySearchBar = () => {
    // display searchbar on mobile
    if (window.innerWidth < 600) setSearchActive(!searchActive);
  }

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <S.Container>
    <S.SearchContainer>
      <S.InputSearchContainer active={searchActive}>
        <S.InputSearch value={searchValue} active={searchActive} type="search" placeholder={getTranslate('search', lang)} onChange={(e) => setSearchValue(e.target.value)} onFocus={() => handleFocus(true)} onBlur={() => handleFocus(false)} />
        <svg onClick={displaySearchBar} id="search" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M507.3 484.7l-141.5-141.5C397 306.8 415.1 259.7 415.1 208c0-114.9-93.13-208-208-208S-.0002 93.13-.0002 208S93.12 416 207.1 416c51.68 0 98.85-18.96 135.2-50.15l141.5 141.5C487.8 510.4 491.9 512 496 512s8.188-1.562 11.31-4.688C513.6 501.1 513.6 490.9 507.3 484.7zM208 384C110.1 384 32 305 32 208S110.1 32 208 32S384 110.1 384 208S305 384 208 384z" /></svg>
      </S.InputSearchContainer>
      {/* <svg id='filter' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M600.9 32C622.5 32 640 49.5 640 71.08C640 80.8 636.4 90.17 629.8 97.36L480 262.2V425.1C480 438.1 470.1 448 457.1 448C453.3 448 448.8 446.5 445 443.8L365.2 385.7C356.9 379.7 352 370.1 352 359.9V262.2L202.2 97.36C195.6 90.17 192 80.8 192 71.08C192 49.5 209.5 32 231.1 32H600.9zM225.8 75.84L379.8 245.2C382.5 248.2 384 252 384 256V359.9L448 406.4V256C448 252 449.5 248.2 452.2 245.2L606.2 75.84C607.3 74.54 608 72.84 608 71.08C608 67.17 604.8 64 600.9 64H231.1C227.2 64 224 67.17 224 71.08C224 72.84 224.7 74.54 225.8 75.84V75.84zM33.84 139.8L187.8 309.2C190.5 312.2 192 316 192 320V391.9L256 438.4V320C256 316 257.5 312.2 260.2 309.2L289.3 277.2C295.2 270.7 305.3 270.2 311.9 276.2C318.4 282.1 318.9 292.2 312.9 298.8L288 326.2V457.1C288 470.1 278.1 480 265.1 480C261.3 480 256.8 478.5 253 475.8L173.2 417.7C164.9 411.7 160 402.1 160 391.9V326.2L10.16 161.4C3.623 154.2 0 144.8 0 135.1C0 113.5 17.5 96 39.08 96H160C168.8 96 176 103.2 176 112C176 120.8 168.8 128 160 128H39.08C35.17 128 32 131.2 32 135.1C32 136.8 32.66 138.5 33.84 139.8V139.8z" /></svg> */}
      {focusActive && <S.Results>
        {Object.values(missions).map(e => <div key={e.mission_id}><S.Result onClick={() => handleResultClick(e.mission_id)}>
          {e.title}
        </S.Result></div>)}
      </S.Results>}
    </S.SearchContainer>
    {industrialConfirmed && <S.Link to="/missions/creation">
      <span>{getTranslate('add', lang)}</span>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M432 256C432 264.8 424.8 272 416 272h-176V448c0 8.844-7.156 16.01-16 16.01S208 456.8 208 448V272H32c-8.844 0-16-7.15-16-15.99C16 247.2 23.16 240 32 240h176V64c0-8.844 7.156-15.99 16-15.99S240 55.16 240 64v176H416C424.8 240 432 247.2 432 256z" /></svg>
    </S.Link>}
  </S.Container>
}

export default Header;

// 
// ─── STYLE DEFINITION ───────────────────────────────────────
//
const S = {}
S.Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`

S.SearchContainer = styled.div`
  display: flex;
  gap: 10px;

  // filter svg
  & #filter {
    padding: 7px;
    background: #fff;
    width: 35px;
    height: 35px;
    border-radius: 5px;
    box-sizing: border-box;
    cursor: pointer;
    fill: ${({ theme }) => theme.black};
  }
`

S.InputSearchContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  // search svg
  & #search {
    position: absolute;
    right: 15px;
    width: 18px;
    height: 18px;
    fill: ${({ theme }) => theme.black};
    cursor: pointer;

    @media(min-width: 450px) {
      display: none;
    }
  }

  @media(max-width: 450px) {
    ${({ active }) => !active && css`
      & #search {
        right: 50%;
        transform: translateX(50%);
      }
    `}
  }
`

S.InputSearch = styled.input`
  height: 35px;
  color: ${({ theme }) => theme.black};
  border: none;
  background: #fff;
  width: 350px;
  border-radius: 5px;
  padding-left: 15px;
  padding-right: 40px;
  box-sizing: border-box;
  font-size: 15px;
  font-family: 'Poppins';
  transition: .3s;

  &:focus {
    outline: none;
  }
  // hide X
  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    -webkit-appearance:none;
  }

  @media(max-width: 600px) {
    width: 250px;
  }
  @media(max-width: 450px) {
    width: 35px;
    padding: 0;

    &::placeholder {
      color: transparent;
    }

    ${({ active }) => active && css`
      padding-left: 15px;
      padding-right: 40px;
      width: 180px;
    `}
  }
`

S.Link = styled(Link)`
  background: ${({ theme }) => theme.linear};
  border: none;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  padding: 0 35px;
  height: 35px;
  box-sizing: border-box;
  border-radius: 5px;

  & > svg {
    display: none;
    width: 20px;
    fill: #fff;
  }
  & span {
    color: #fff;
  }

  @media(max-width: 1100px) {
    margin-right: 45px;
  }
  @media(max-width: 650px) {
    padding: 5px 9px;
    width: 35px;

    & > svg {
      display: block;
    }
    & > span {
      display: none;
    }
  }
`

S.Results = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 65px;
  background: ${({ theme }) => theme.text};
  border-radius: 5px;
  width: 350px;
  z-index: 100;
  background: ${({ theme }) => theme.inputBg};
  max-height: 230px;
  overflow-y: auto;

  &::-webkit-scrollbar-thumb {
    background-color: #6e6e6e;
  }
`

S.Result = styled.div`
  padding: 5px 10px;
  transition: .3s;
  cursor: pointer;
  max-width: 350px;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover {
    background: #e4e4e4;
  }
  &:first-child {
    padding-top: 10px;
    border-radius: 5px 5px 0 0;
  }
  &:last-child {
    padding-bottom: 10px;
    border-radius: 0 0 5px 5px;
  }
`