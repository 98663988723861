import { Outlet } from "react-router-dom";
import Headband from "./Headband";
import Header from "./Header";
import Navigation from "./Navigation";

const Layout = () => {
  return (
    <main className="App">
      <Navigation />
      <div className="container">
        <Headband />
        <Header />
        <Outlet />
      </div>
    </main>
  )
}

export default Layout
