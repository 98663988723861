// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import React, { useState, useEffect } from 'react';
import Api from '../contexts/Api';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const Message = ({ message }) => (
  <section>
    <p>{message}</p>
  </section>
);

const SuccessDisplay = () => {
  return (
    <section>
      <div className="product Box-root">
        <div className="description Box-root">
          <h3>Souscription à l'abonnement effectuée</h3>
        </div>
      </div>
    </section>
  );
};

const Subscription = () => {
  let [message, setMessage] = useState('');
  let [success, setSuccess] = useState(false);

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    //
    // ─── CHECK IF SUBSCRIPTION IS VALIDE THANKS TO SESSION DATA ───────────────────────────────────────
    //
    const validateSubscription = async () => {
      const params = {
        data: {
          session_id: query.get('session_id')
        },
        endpoint: '/stripe/subscriptions/validate'
      }
      const subscriptionValidated = await Api(params);
      if (subscriptionValidated.success) {
        setSuccess(true);
      }
    }

    if (query.get('success')) {
      validateSubscription();
    }

    if (query.get('canceled')) {
      setSuccess(false);
      setMessage("La souscription a été annulée, rendez-vous sur l'onlet \"Mon compte\" pour vous abonner");
    }
  }, []);

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────

  if (success) {
    return <SuccessDisplay />;
  } else {
    return <Message message={message} />;
  }

};

export default Subscription;