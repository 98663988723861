// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import { useEffect, useRef, useState } from 'react';
import { Navigate, Link } from 'react-router-dom';
import styled from 'styled-components';
import Cookies from 'universal-cookie';
import Api from '../contexts/Api';
import { getTranslate, getValue } from '../contexts/Utils';
import { useNotification } from '../components/UseNotification';
import Steps from '../components/Steps';
import SwitchTheme from '../components/SwitchTheme';
import Loader from '../components/Loader';
import SelectAutocomplete from '../components/SelectAutocomplete';
import CGUCheck from '../components/CGUCheck';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const Register = () => {
  let lang = 'fr';

  const { notification } = useNotification()
  const cookies = new Cookies();
  const userData = cookies.get('nudge_user');
  const selectedCategories = useRef([]);

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false)
  const [type, setType] = useState(1);
  const [statusExpert, setStatusExpert] = useState(1);
  const [allCategories, setAllCategories] = useState(false);
  const [errors, setErrors] = useState({});

  // 
  // ─── GET CATEGORIES + TYPES & SETTINGS ───────────────────────────────────────
  //
  useEffect(() => {
    const getData = async () => {
      await Promise.all([
        Api({ endpoint: '/categories', method: 'GET' }),
        Api({ endpoint: '/data', method: 'GET' })
      ]).then(values => {
        // 
        // ─── SET WINDOW VARIABLE ───────────────────────────────────────
        //
        window._DATA = {
          categories: values[0].data,
          ...values[1].data
        };

        setData({
          categories: values[0].data.map(e => ({ id: e.category_id, name: e.name })),
          ...values[1].data
        })
      });
    }

    getData();
  }, [])

  // 
  // ─── HANDLE USER REGISTER ───────────────────────────────────────
  //
  const handleSubmit = async (fields) => {
    if (!fields.all_categories) {
      fields.categories = selectedCategories.current.map(e => e.id).join(',');
    }

    fields.description = JSON.stringify({ [lang]: fields.description });
    setLoading(true)

    // handle subscription type
    let subscription_type = fields.subscription_type;
    delete fields.subscription_type;

    const params = {
      data: { fields },
      endpoint: '/auth/register'
    }
    const logUser = await Api(params)

    // 
    // ─── ON SUCCESS WE USE REDUX TO LOG USER ───────────────────────────────────────
    //
    if (logUser.success && logUser.access_token) {
      cookies.set('nudge_user', logUser.data, {
        expires: new Date(Date.now() + 604800000), // time until expiration
        secure: false, // true if using https
      })

      if (logUser.data.role == 1) {
        await Api({ data: { user_id: logUser.data.user_id }, endpoint: '/stripe/customers' })
        const stripeSubscription = await Api({ data: { user_id: logUser.data.user_id, subscription_type }, endpoint: '/stripe/subscriptions' });
        window.location.href = stripeSubscription.url
      } else if (logUser.data.role == 2) {
        await Api({ data: { user_id: logUser.data.user_id }, endpoint: '/stripe/accounts' })
        const stripeAccount = await Api({ data: { user_id: logUser.data.user_id }, endpoint: '/stripe/accounts/link' });
        window.location.href = stripeAccount.url
      }
    } else {
      notification({ variant: 'error', message: logUser.message })
    }
  }

  let isExpert = type === 2
  let isIndustrial = type === 1
  let isPro = (isExpert && statusExpert === 1) || isIndustrial

  // 
  // ─── FORMFIELDS ───────────────────────────────────────
  //
  let formFields = [
    [
      [
        {
          component: "select",
          name: "type",
          label: 'Type',
          required: true,
          data: [{ id: 1, name: getTranslate(data?.types?.user_role[1]?.label, lang) }, { id: 2, name: data?.types?.user_role[2]?.label }],
          handleChange: setType
        },
        {
          component: "select",
          name: "status_expert",
          label: getTranslate('status'),
          required: true,
          data: [{ id: 1, name: 'Professionnel' }, { id: 2, name: 'Particulier' }],
          handleChange: setStatusExpert,
          cond: isExpert
        },
      ],
      [
        { type: 'radio', component: 'radio', name: 'subscription_type', label: `Choix de l'abonnement (gratuit ${data?.settings?.stripe_trial_period_days || ''} jours)`, required: true, value: "monthly", values: [{ label: 'Mensuel (55€ HT)', value: "monthly" }, { label: 'Annuel (600€ HT)', value: "yearly" },], cond: type === 1 }
      ],
      { component: "text", name: "firstname", label: getTranslate('firstname', lang), required: true },
      { component: "text", name: "name", label: getTranslate('name', lang), required: true },
      { type: "email", component: "text", name: "email", label: "Email", required: true },
      { type: 'tel', component: "text", name: "phone", label: getTranslate('phone', lang), required: true },
      { type: "password", component: "text", name: "password", label: getTranslate('password', lang), required: true },
      { component: "text", name: "address", label: getTranslate('address', lang), required: true },
      { component: "text", name: "cp", label: getTranslate('postal_code', lang), required: true },
      { component: "text", name: "city", label: getTranslate('city', lang), required: true },
    ],
    [
      { cond: !allCategories, custom_component: <SelectAutocomplete array={data.categories} ref={selectedCategories} width='calc(50% - 10px)' /> },
      { component: 'switch', label: 'Voir toutes les catégories', name: 'all_categories', handleChange: () => setAllCategories(!allCategories) },
      { cond: isExpert, component: "text", type: 'number', name: "reactivity", label: getTranslate('reactivity', lang), required: true },
      { cond: !isIndustrial, component: "text", multiline: true, name: "description", label: 'Description', required: true, shrink: true, size: 'calc(50% - 10px)' },
      { custom_component: <CGUCheck error={getValue(errors, ['cgu', 'message'])} /> },
    ]
  ]

  if ((isExpert && statusExpert === 1) || isIndustrial) {
    formFields.splice(1, 0, [
      {
        component: "select",
        name: "legal_status",
        label: getTranslate('legal_status'),
        required: true,
        data: Object.values(data).length && Object.values(data.types.legal_status).map(e => ({ id: e.value, name: e.label })),
        cond: isPro,
      },
      { cond: isPro, component: "text", name: "company_name", label: getTranslate('company_name', lang), required: true },
      { cond: isPro, component: "text", name: "siret", label: "Siret", required: true },
      { cond: isPro, component: "text", name: "address_pro", label: getTranslate('address_pro', lang), required: true },
      { cond: isPro, component: "text", name: "cp_pro", label: getTranslate('postal_code_pro', lang), required: true },
      { cond: isPro, component: "text", name: "city_pro", label: getTranslate('city_pro', lang), required: true },
      { cond: isPro, component: "text", type: "number", name: "social_capital", label: getTranslate('social_capital', lang), required: true },
      [
        { component: 'checkbox', name: 'expert_tva', label: "TVA applicable", cond: isExpert },
      ],
      { custom_component: "Les informations collectées ci-dessus seront uniquement utilisées pour la facturation." }
    ])
  }

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return userData && !loading ? <Navigate to="/" /> : <S.Container>
    <S.Content>
      <S.Header>
        <svg xmlns="http://www.w3.org/2000/svg" width="396" height="102" viewBox="0 0 396 102">
          <text id="NUDGE_TECH" data-name="NUDGE TECH" transform="translate(0 84)" fill="#004e91" fontSize="83" fontFamily="Impact"><tspan x="0" y="0">NUDGE</tspan><tspan y="0" xmlSpace="preserve" fill="#86b2d8"> TECH</tspan></text>
        </svg>
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" width="122.739" height="114.537" viewBox="0 0 122.739 114.537">
            <g id="Fonf_projet" data-name="Fonf projet" transform="translate(-655.761 -1733.389)">
              <path id="Tracé_34" data-name="Tracé 34" d="M40.9,6.95c22.591,0,40.9-18.23,40.9,4.361a40.9,40.9,0,1,1-81.81,0C0-11.28,18.314,6.95,40.9,6.95Z" transform="matrix(-0.574, 0.819, -0.819, -0.574, 778.5, 1763.339)" opacity="0.7" style={{ mixBlendMode: 'multiply', isolation: 'isolate' }} />
              <path id="Tracé_35" data-name="Tracé 35" d="M40.9,6.95c22.591,0,40.9-18.23,40.9,4.361a40.9,40.9,0,0,1-81.81,0C0-11.28,18.314,6.95,40.9,6.95Z" transform="matrix(0.438, 0.899, -0.899, 0.438, 702.692, 1751.507)" opacity="0.33" style={{ mixBlendMode: 'multiply', isolation: 'isolate' }} />
            </g>
          </svg>
          <span>{getTranslate('signup', lang)}</span>
        </div>
      </S.Header>
      {!loading && <Steps handleSubmit={handleSubmit} fields={formFields} errors={errors} setErrors={setErrors} />}
      {loading && <Loader />}
      <S.Footer>
        <p>{getTranslate('already_account', lang)} <Link to='/connexion'>{getTranslate('login', lang)}</Link></p>
        <S.LinksContainer>
          <S.Link to='/cgu'>CGU</S.Link>
          <S.Link to='/cgv'>CGV</S.Link>
          <S.Link to='/mentions-legales'>Mentions Légales</S.Link>
          <S.Link as='a' href="https://nudgetech.fr/" target="_blank" rel="noopener noreferrer">Site internet</S.Link>
        </S.LinksContainer>
      </S.Footer>
    </S.Content>
    <SwitchTheme />
  </S.Container>
};

export default Register;

// 
// ───  STYLE DEFINITION ───────────────────────────────────────
//
const S = {}
S.Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 20px;
  box-sizing: border-box;
  background: ${({ theme }) => theme.card};
  min-height: 100vh;
  height: fit-content;
`

S.Content = styled.div`
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1000px;
  height: auto;
  min-height: 100%;
  box-sizing: border-box;
  margin: 0 20px;
  
  @media(max-width: 750px) {
    min-width: unset;
    width: 100%;
  }
`

S.Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 35px;
  margin-bottom: 60px;

  // nudge tech svg
  & > svg {
    width: 230px;
  }

  // connexion + svg
  & div {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    font-weight: bold;
    font-size: 16px;

    & svg {
      position: absolute;
      width: 60px;
      z-index: -1;
      fill: ${({ theme }) => theme.blue};
    }
  }
`

S.Footer = styled.div`
  font-size: 14px;
  padding: 20px 0;
  box-sizing: border-box;
  width: 100%;
  text-align: center;
  margin-top: auto;

  & p > a {
    font-weight: bold;
    background-image: ${({ theme }) => theme.linear};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; 
    background-clip: text;
    -moz-text-fill-color: transparent;
  }
`

S.Link = styled(Link)`
  font-size:0.8em;
  color:${({ theme }) => theme.blue} !important;
`

S.LinksContainer = styled.div`
  display:flex;
  justify-content:center;
  gap:10px;
`