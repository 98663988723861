import { useEffect, useRef, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import styled, { css } from "styled-components";
import Cookies from "universal-cookie";
import Api from "../contexts/Api";
import MissionView from "../containers/MissionView";
import MissionCard from "../containers/MissionCard";
import Loader from "../components/Loader";
import { getTranslate } from "../contexts/Utils";

const Missions = (props) => {
  const { lang = 'fr', status, search = false } = props;
  const { pathname } = useLocation();
  const { uuid, params } = useParams();
  const cookies = new Cookies();
  const user = cookies.get('nudge_user');
  const role = user.role;

  const [missions, setMissions] = useState({});
  const [currentMission, setCurrentMission] = useState("");
  const [mobileViewActive, setMobileViewActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [endReached, setEndReached] = useState(false);
  const [filters, setFilters] = useState([]);

  // no resulsts message
  let noResultsMessage = getTranslate('no_missions', lang)
  if (role === 1 && window.location.pathname === "/") noResultsMessage = getTranslate('no_mission_created', lang);
  const industrialConfirmed = role === 1 && [1, 2].includes(user.subscription_status);

  // init height
  const userConfirmed = user.role === 3 || ((user.role === 1 && [1, 2].includes(user.subscription_status)) || (user.role === 2 && Boolean(user.account_confirmed)))
  let initHeight = 116
  if (!userConfirmed) initHeight += 95
  const [height, setHeight] = useState(initHeight);

  // get categories
  const cat = window._DATA && window._DATA.categories;
  const categories = {};
  cat && cat.forEach(e => categories[e.category_id] = e);

  // 
  // ─── GET MISSIONS ───────────────────────────────────────
  //
  const getMissions = async (initFilters = [], firstRender = false) => {
    setLoading(true);
    let query = {};

    if (search) query.mission_id = uuid;

    // pro id on all of his routes
    if (role === 1) query.user_create = user.user_id;

    // expert id on his ongoing / history routes
    if (!search && (role === 2 && status && !status.includes(1))) query.expert_id = user.user_id;

    if (role !== 3) query.deleted = 0;

    if (role === 2) query.published = true;

    query.categories = initFilters ? initFilters : filters;

    if (status) query.status = status;

    query.offset = firstRender ? 0 : offset;

    const params = {
      query,
      endpoint: "/missions",
      method: 'GET'
    }

    const { data } = await Api(params);

    if (data) {
      if (firstRender) setMissions(data);
      else setMissions((state) => ({ ...state, ...data }));

      if (firstRender || offset === 0) handleCardClick(Object.values(data)[0].mission_id, firstRender);
    }
    // there is no missions with these filters
    else if ((filters.length && offset === 0) || firstRender) setMissions({});

    if (!data || data.length < 4) setEndReached(true);
    setLoading(false);
  }

  // 
  // ─── RE RENDER WHEN ROUTE CHANGES ───────────────────────────────────────
  //
  useEffect(() => {
    lastElement.current = null;
    setCurrentMission('');
    setMissions({});
    setMobileViewActive(false);

    let initFilters = [];
    // set filters
    if (params) { }
    // default filters for expert on all missions view
    else if (role === 2 && status && status.includes(1) && user.categories && !Boolean(user.all_categories)) {
      initFilters = user.categories.split(',');
      setFilters(user.categories.split(','));
    }
    else setFilters([]);
    getMissions(initFilters, true);

    if (offset !== 0) setOffset(0);
    setEndReached(false);
  }, [pathname])

  //
  // ─── INFINITE SCROLL ───────────────────────────────────────
  //
  const lastElement = useRef(null);

  // reached when scrolling to the last card
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 1,
    };

    const observer = new IntersectionObserver((entries) => {
      const [entry] = entries;
      if (entry.isIntersecting && !loading && !endReached) {
        setOffset((prev) => prev + 4);
      }
    }, options);

    if (lastElement.current) observer.observe(lastElement.current);

    return () => {
      if (lastElement.current) observer.unobserve(lastElement.current);
    };
  }, [lastElement, endReached, loading]);

  // when offset changes add new missions to the list
  useEffect(() => {
    if (offset !== 0) getMissions();
  }, [offset]);

  // 
  // ─── HANDLE CLICK ON A CARD ───────────────────────────────────────
  //
  const handleCardClick = async (id, firstRender) => {
    setCurrentMission(id);

    // change view on mobile
    if (window.innerWidth < 900 && !firstRender) setMobileViewActive(true);
  }

  //
  // ─── CATEGORIES RENDER ───────────────────────────────────────
  //
  const getCategoriesRender = (missionCat = [], moreCat) => {
    const render = missionCat.map((e, i) => {
      if (i > 3 && !moreCat) return null;
      if (e in categories) return <span key={i}>{categories[e].name}</span>
      return null;
    })
    return render;
  }

  //
  // ─── HANDLE DELETE FILTER ───────────────────────────────────────
  //
  const handleDeleteFilter = (id) => {
    let initFilters = filters.filter(e => e !== id);
    setFilters(initFilters);
    getMissions(initFilters, true);
  }

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <S.Container>
    <S.Filters>
      {filters.map(filter => <span key={filter}>{categories[filter].name} <svg onClick={() => handleDeleteFilter(filter)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z" /></svg></span>)}
    </S.Filters>
    {Boolean(Object.keys(missions).length) ? <S.Content size={height} filters={Boolean(filters.length)}>
      <S.MissionsContainer active={!mobileViewActive}>
        {Object.values(missions).map((e, i) =>
          <MissionCard
            key={e.mission_id}
            index={i}
            obj={e}
            lang={lang}
            getCategoriesRender={getCategoriesRender}
            missions={missions}
            lastElement={lastElement}
            currentMission={currentMission}
            handleCardClick={handleCardClick}
          />)}
        {loading && <Loader />}
      </S.MissionsContainer>
      {currentMission && <S.MissionViewContainer active={mobileViewActive}>
        <MissionView
          lang={lang}
          missions={missions}
          setMissions={setMissions}
          currentMission={currentMission}
          setCurrentMission={setCurrentMission}
          setMobileViewActive={setMobileViewActive}
          getCategoriesRender={getCategoriesRender}
        />
      </S.MissionViewContainer>}
    </S.Content>
      : <S.NoResults>
        <img src={process.env.PUBLIC_URL + '/img/no_results.svg'} alt='no results' />
        <p>{noResultsMessage}</p>
        {industrialConfirmed && window.location.pathname === '/' && <S.Link to="/missions/creation">{getTranslate('add_mission', lang)}</S.Link>}
      </S.NoResults>}
  </S.Container>
}

export default Missions;

// 
// ─── STYLE DEFINITION ───────────────────────────────────────
//
const S = {}
S.Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: calc(100% - 140px);
`

S.Content = styled.div`
  display: flex;
  gap: 5px;
  min-height: ${({ size, filters }) => `calc(100vh - ${(size || 0) + (filters ? 27 : 0)}px)`};
  height: ${({ size, filters }) => `calc(100vh - ${(size || 0) + (filters ? 27 : 0)}px)`};
`

S.Filters = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 7px;

  & > span {
    background: #e6e6e6;
    color: #000;
    height: fit-content;
    padding: 4px 10px;
    border-radius: 5px;
    font-size: 13px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    gap: 8px;

    & > svg {
      width: 18px;
      height: 18px;
      fill: #595959;
      cursor: pointer;
    }
  }
`

S.MissionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 50%;
  overflow: auto;
  padding-right: 5px;

  @media(max-width: 850px) {
    display: none;
    width: 100%;

    ${({ active }) => active && css`
      display: flex;
    `}
  }
`

S.MissionViewContainer = styled.div`
  height: 100%;
  width: 50%;
  padding-right: 5px;
  background: ${({ theme }) => theme.card};
  border-radius: 10px;

  @media(max-width: 850px) {
    display: none;
    width: 100%;

    ${({ active }) => active && css`
      display: block;
    `}
  }
`

S.NoResults = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  & img {
    width: 40%;
  }
  & p {
    font-weight: lighter;
  }
`

S.Link = styled(Link)`
  background: ${({ theme }) => theme.linear};
  border: none;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  padding: 0 35px;
  height: 35px;
  box-sizing: border-box;
  border-radius: 5px;
`