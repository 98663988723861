import styled from "styled-components";
import { formatDate, getTranslate } from "../contexts/Utils";

const MissionCard = (props) => {
  const { obj, index, lang, getCategoriesRender, handleCardClick, currentMission, lastElement, missions } = props;
  // get status
  const data = window._DATA;
  const statusList = data.types.mission_status;

  const { mission_id, title, status: missionStatus, categories: missionCat, content, time_online, deleted } = obj;
  const time = timeSince(new Date(time_online));
  const contentRender = { __html: JSON.parse(content)[lang] } || { __html: '' };

  // 
  // ─── GET TIME SINCE TODAY ───────────────────────────────────────
  //
  function timeSince(date) {
    var periods = {
      month: 30 * 24 * 60 * 60 * 1000,
      week: 7 * 24 * 60 * 60 * 1000,
      day: 24 * 60 * 60 * 1000,
      hour: 60 * 60 * 1000,
      minute: 60 * 1000
    };
    var diff = Date.now() - date;

    if (diff > periods.week) return formatDate({ time: date, display: 'date' });
    if (diff > periods.day) return `${Math.floor(diff / periods.day)}${lang === 'fr' ? 'j' : 'd'}`;
    if (diff > periods.hour) return Math.floor(diff / periods.hour) + "h";
    if (diff > periods.minute) return Math.floor(diff / periods.minute) + "m";
    return getTranslate('just_now', lang);
  }

  const getStatusName = (status, deleted) => {
    if (deleted) return getTranslate('deleted', lang);
    if (missionStatus === 1 && !obj.published) return getTranslate('pending_publication', lang);
    if (getTranslate(statusList[status].label, lang)) return getTranslate(statusList[status].label, lang);
    return statusList[status].label;
  }

  return <S.Container
    onClick={() => handleCardClick(mission_id)}
    ref={index === Object.keys(missions).length - 1 ? lastElement : null}
    status={Boolean(deleted) ? 7 : missionStatus}
    active={currentMission === mission_id}
  >
    <S.Header>
      {title && <span className="title">{title}</span>}
      {time_online !== undefined && <div className="time_online">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M240 112C240 103.2 247.2 96 256 96C264.8 96 272 103.2 272 112V247.4L360.9 306.7C368.2 311.6 370.2 321.5 365.3 328.9C360.4 336.2 350.5 338.2 343.1 333.3L247.1 269.3C242.7 266.3 239.1 261.3 239.1 256L240 112zM256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0zM32 256C32 379.7 132.3 480 256 480C379.7 480 480 379.7 480 256C480 132.3 379.7 32 256 32C132.3 32 32 132.3 32 256z" /></svg>
        <span>{time}</span>
      </div>}
    </S.Header>
    <div className="status-price">
      {missionStatus && <div className="status">
        <span>{getStatusName(missionStatus, Boolean(deleted))}</span>
      </div>}
      {obj.price && <span className="price">{getTranslate(missionStatus === 3 ? 'proposed_price' : 'price', lang)} : {obj.price}€ HT</span>}
    </div>
    {missionCat && Object.keys(missionCat).length && <div className="categories">
      <div className="categories">
        {getCategoriesRender(missionCat, false)}
        <p className="more-cat">...</p>
      </div>
    </div>}
    {content && <p className="content" dangerouslySetInnerHTML={contentRender}></p>}
  </S.Container>
}

export default MissionCard;

const S = {}
S.Container = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.card};
  padding: 20px;
  box-sizing: border-box;
  border-radius: 10px;
  gap: 5px;
  position: relative;
  cursor: pointer;
  border: ${({ active, theme }) => active ? `2px solid ${theme.title}` : '2px solid transparent'};

  & .status-price {
    display: flex;
    gap: 20px;

    & .price {
      color: #B4B4B4;
      font-weight: bold;
      font-size: 13.5px;
    }

    & .status {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    margin-left: 15px;
    
    & > span {
      color: #B4B4B4;
      font-weight: bold;
      font-size: 13.5px;
    }
    &::after {
      content: "";
      position: absolute;
      left: -15px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: ${({ status }) => {
    switch (status) {
      case 1: return '#A0EA99';
      case 2: return '#27bfd1';
      case 3: return '#27bfd1';
      case 4: return '#f6b912';
      case 5: return '#410cf4';
      case 6: return '#008a00';
      case 7: return '#f4240c';
      default: return '#A0EA99';
    }
  }}
    }
  }
  }
  & .categories {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    & > span {
      border-radius: 30px;
      background: ${({ theme }) => theme.title};
      color: ${({ theme }) => theme.textInverse};
      font-size: 12px;
      font-weight: bold;
      padding: 4px 11px;
    }
  }
  & .content {
    font-size: 13px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin: 0;
    font-weight: lighter;
  }
  .more-cat {
    margin: 0;
  }
`

S.Header = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;

  & .time_online {
    display: flex;
    gap: 10px;
    font-size: 12px;
    color: #B4B4B4;

    & > span {
      color: #B4B4B4;
      white-space: nowrap;
    }
    & > svg {
      fill: #B4B4B4;
      width: 15px;
      height: 15px;
    }
  }
  & .title {
    font-size: 19px;
    font-weight: bold;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`

S.Button = styled.button`
  background: ${({ theme }) => theme.linear};
  border-radius: 50%;
  border: none;
  width: 25px;
  height: 25px;
  color: #fff;
  cursor: pointer;

  @media(max-width: 850px) {
    display: none;
  }
`