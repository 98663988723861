import styled from "styled-components";

const CGU = () => {
  return <S.Container>
    <h1>CONDITIONS GENERALES D’UTILISATION</h1>

    <span>En vigueur au 01/01/2023</span>

    <h2>ARTICLE 1 - Objet et Champ d'application</h2>

    <p>Les présentes Conditions Générales d’Utilisation (dites « CGU ») s'appliquent, sans restriction ni réserve, à
      l’utilisation des services suivants :
      <br />
      « Mise en relation, via une plateforme internet, de tous professionnels avec des experts dans le domaine de l'industrie
      en vue de l'obtention de conseils techniques et de la réalisation de prestations techniques»
      tels que proposés par le Prestataire aux clients professionnels/industriels (« Les Clients ou le Client ») sur le site <a href="https://www.nudgetech.fr">https://www.nudgetech.fr</a></p>
    <p>Les caractéristiques principales des Services sont présentées sur le site internet : <a href="https://www.nudgetech.fr">https://www.nudgetech.fr</a></p>
    <p>Le Client est tenu d'en prendre connaissance avant toute utilisation du site internet.</p>
    <p>Le choix et l'achat d'un Service est de la seule responsabilité du Client.</p>
    <p>Ces CGU sont accessibles à tout moment sur le site <a href="https://www.nudgetech.fr">https://www.nudgetech.fr</a> et prévaudront sur tout autre document.</p>
    <p>Le Client déclare avoir pris connaissance des présentes CGU et les avoir acceptées.</p>
    <p>Dans tous les cas, l’accès à la plateforme internet vaut pour acceptation automatique de ces CGU.</p>

    <h2>ARTICLE 2 – Mentions légales</h2>

    <p>Les coordonnées du Prestataire sont les suivantes :</p>
    <p>NUDGE TECH (EURL)</p>
    <p>Capital social de 4000 euros</p>
    <p>Immatriculé au RCS de COLMAR, sous le numéro 921082574</p>
    <p>23A rue de FESSENHEIM, 68740 BALGAU</p>
    <p>mail : regis.schmidt@nudgetech.fr / regis.schmidt@wanadoo.fr</p>
    <p>téléphone : 0783310746</p>

    <br />

    <p>L’hébergeur du site <a href="https://www.nudgetech.fr">https://www.nudgetech.fr</a> est la société CACTUS, sise au 8 rue des Cailles, 68270 Ruelisheim
      (France).</p>

    <h2>ARTICLE 3 – Accès au site</h2>

    <p>Le site <a href="https://www.nudgetech.fr">https://www.nudgetech.fr</a> permet d’avoir accès aux services suivants :</p>
    <ul>
      <li>Mise en relation avec des experts techniques dans tous les domaines industriels existants.
        Le site est accessible gratuitement depuis n’importe où et par tout utilisateur disposant d’un accès à Internet.
        Après inscription, aussi bien les clients que les experts auront accès aux différents services à l’aide d’un identifiant
        et d’un mot de passe.</li>
    </ul>
    <p>Pour des raisons de maintenance ou autres, l’accès au site peut être interrompu ou suspendu par l’éditeur sans préavis
      ni justification.</p>

    <h2>ARTICLE 4 – Collecte des données</h2>

    <p>Pour la création du compte de l’Utilisateur, la collecte des informations au moment de l’inscription sur le site est
      nécessaire et obligatoire. Conformément à la loi n°78-17 du 6 janvier relative à l’informatique, aux fichiers et aux
      libertés, la collecte et le traitement d’informations personnelles s’effectuent dans le respect de la vie privée.</p>
    <p>Suivant la loi Informatique et Libertés en date du 6 janvier 1978, articles 39 et 40, l’Utilisateur dispose du droit
      d’accéder, de rectifier, de supprimer et d’opposer ses données personnelles. L’exercice de ce droit s’effectue
      par l’envoi d’un courrier ou d’un mail à l’administrateur du site (hello@nudgetech.fr)</p>

    <br />

    <p>Le Client est informé que la collecte de ses données à caractère personnel est nécessaire à la vente des Services et
      leur réalisation et leur délivrance, ainsi qu'à leur transmission à des tiers intervenant dans la réalisation des
      Services. Ces données à caractère personnel sont récoltées uniquement pour l’exécution du contrat de prestations de
      services.</p>

    <h2>ARTICLE 5 - Propriété intellectuelle</h2>

    <p>Le contenu du site <a href="https://www.nudgetech.fr">https://www.nudgetech.fr</a> (marques, logos, illustrations graphiques, fichier Experts, textes…) est la
      propriété du Vendeur (droit d’auteur) et de ses partenaires et est protégé par les lois françaises et internationales
      relatives à la propriété intellectuelle.</p>
    <p>Toute reproduction totale ou partielle de ce contenu est strictement interdite et est susceptible de constituer un délit
      de contrefaçon.</p>

    <h2>ARTICLE 6 - Responsabilité</h2>

    <p>Bien que les informations publiées sur le site soient réputées fiables, le site se réserve la faculté d’une non-garantie
      de la fiabilité des sources.</p>
    <p>Les informations diffusées sur le site <a href="https://www.nudgetech.fr">https://www.nudgetech.fr</a> sont présentées à titre purement informatif et sont sans
      valeur contractuelle.</p>
    <p>En dépit des mises à jour régulières, la responsabilité du site ne peut être engagée en cas de modification des
      dispositions administratives et juridiques apparaissant après la publication. Il en est de même pour l’utilisation et
      l’interprétation des informations communiquées sur la plateforme.</p>
    <p>Le site décline toute responsabilité concernant les éventuels virus pouvant infecter le matériel informatique de
      l’Utilisateur après l’utilisation ou l’accès à ce site.</p>
    <p>Le site ne peut être tenu pour responsable en cas de force majeure ou du fait imprévisible et insurmontable d’un tiers.</p>
    <p>La garantie totale de la sécurité et la confidentialité des données n’est pas assurée par le site. Cependant, le site s’engage à mettre en œuvre toutes les méthodes requises pour le faire au mieux.</p>

    <h2>ARTICLE 7 – Liens hypertextes</h2>

    <p>Le site peut être constitué de liens hypertextes. En cliquant sur ces derniers, l’Utilisateur sortira de la plateforme. Cette dernière n’a pas de contrôle et ne peut pas être tenue responsable du contenu des pages web relatives à ces liens.</p>

    <h2>ARTICLE 8 – Cookies</h2>

    <p>Lors des visites sur le site, l’installation automatique d’un cookie sur le logiciel de navigation de l’Utilisateur peut
      survenir.</p>
    <p>Les cookies correspondent à de petits fichiers déposés temporairement sur le disque dur de l’ordinateur de
      l’Utilisateur. Ces cookies sont nécessaires pour assurer l’accessibilité et la navigation sur le site. Ces fichiers ne
      comportent pas d’informations personnelles et ne peuvent pas être utilisés pour l’identification d’une personne.</p>
    <p>L’information présente dans les cookies est utilisée pour améliorer les performances de navigation sur le site
      <a href="https://www.nudgetech.fr">https://www.nudgetech.fr</a></p>
    <p>En naviguant sur le site, l’Utilisateur accepte les cookies. Leur désactivation peut s’effectuer via les paramètres du
      logiciel de navigation.</p>

    <h2>ARTICLE 9 – Publication par l’Utilisateur</h2>

    <p>Le site <a href="https://www.nudgetech.fr">https://www.nudgetech.fr</a> permet aux clients industriels de publier des commentaires.</p>
    <p>Dans ses publications, le client est tenu de respecter les règles de la Netiquette ainsi que les règles de droit en
      vigueur.</p>
    <p>Le site dispose du droit d’exercer une modération à priori sur les publications et peut refuser leur mise en ligne sans
      avoir à fournir de justification.</p>
    <p>Le membre garde l’intégralité de ses droits de propriété intellectuelle. Toutefois, toute publication sur le site
      implique la délégation du droit non exclusif et gratuit à la société éditrice de représenter, reproduire, modifier,
      adapter, distribuer et diffuser la publication n’importe où et sur n’importe quel support pour la durée de la propriété
      intellectuelle. Cela peut se faire directement ou par l’intermédiaire d’un tiers autorisé. Cela concerne notamment le
      droit d’utilisation de la publication sur le web et sur les réseaux de téléphonie mobile.</p>
    <p>À chaque utilisation, l’éditeur s’engage à mentionner le nom du membre à proximité de la publication.</p>
    <p>L’Utilisateur est tenu responsable de tout contenu qu’il met en ligne. L’Utilisateur s’engage à ne pas publier de
      contenus susceptibles de porter atteinte aux intérêts de tierces personnes. Toutes procédures engagées en justice par un
      tiers lésé à l’encontre du site devront être prises en charge par l’Utilisateur.</p>
    <p>La suppression ou la modification par le site du contenu de l’Utilisateur peut s’effectuer à tout moment, pour n’importe
      quelle raison et sans préavis.</p>

    <h2>ARTICLE 10 – Durée du contrat</h2>

    <p>Le présent contrat est valable pour une durée indéterminée. Le début de l’utilisation des services du site marque l’application du contrat à l’égard de l’Utilisateur (industriels et/ou experts).</p>

    <h2>ARTICLE 11 – Droit applicable et juridiction compétente</h2>

    <p>Le présent contrat est soumis à la législation française. L’absence de résolution à l’amiable des cas de litige entre les parties implique le recours aux tribunaux français compétents pour régler le contentieux.</p>
  </S.Container>
}

export default CGU;

const S = {}
S.Container = styled.div`
  background: ${({ theme }) => theme.card};
  color: ${({ theme }) => theme.text};
  box-sizing: border-box;
  border-radius: 5px;
  font-weight: lighter;
  font-size: 15px;
  padding: 30px;

  & h1 {
    margin-top: 0;
  }

  & a {
    color: ${({ theme }) => theme.title};
  }

  & p {
    margin: 0
  }
`

S.Content = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 30px;
  border-radius: 10px;
  overflow-y: auto;
`