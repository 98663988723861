import { useContext } from "react";
import styled from "styled-components";
import { Context } from "../App";
import { getTranslate } from "../contexts/Utils";

const SwitchTheme = ({ lang }) => {
  const { theme, setTheme } = useContext(Context);

  // 
  // ─── SWITCH THEME ───────────────────────────────────────
  //
  const handleThemeChange = () => {
    setTheme((state) => {
      const newTheme = state === 'light' ? 'dark' : 'light';
      localStorage.setItem('nudge_theme', newTheme);
      return newTheme;
    });
  }

  return <S.Container onClick={handleThemeChange} height={22}>
    {theme === 'light' ?
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M428.3 367.5c-5.609-9.312-16.08-13.91-26.75-11.97c-42.39 8.188-85.83-2.938-119.2-30.5C248.4 297 228.9 255.5 228.9 211.3c0-52.75 28.2-101.8 73.59-127.8c9.453-5.406 14.38-15.88 12.55-26.66c-1.812-10.75-9.891-19-20.61-21C283.1 33.66 265.3 32 253.9 32C131.5 32 32 132.5 32 256s99.55 224 221.9 224c67.19 0 130-30.25 172.4-83C433.1 388.4 433.9 376.9 428.3 367.5zM253.9 448C149.2 448 64 361.9 64 256s85.19-192 189.9-192c4.891 0 11.53 .4062 18.09 1.031C225.3 98.34 196.9 152.9 196.9 211.3c0 53.84 23.7 104.3 65.05 138.5c36.25 29.94 82.17 43.88 128.5 39.69C354.8 426.8 305.8 448 253.9 448z" /></svg>
      : <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M240 16C240 7.164 247.2 0 256 0C264.8 0 272 7.164 272 16V104C272 112.8 264.8 120 256 120C247.2 120 240 112.8 240 104V16zM160 256C160 202.1 202.1 160 256 160C309 160 352 202.1 352 256C352 309 309 352 256 352C202.1 352 160 309 160 256zM256 320C291.3 320 320 291.3 320 256C320 220.7 291.3 192 256 192C220.7 192 192 220.7 192 256C192 291.3 220.7 320 256 320zM256 512C247.2 512 240 504.8 240 496V416C240 407.2 247.2 400 256 400C264.8 400 272 407.2 272 416V496C272 504.8 264.8 512 256 512zM0 256C0 247.2 7.164 240 16 240H96C104.8 240 112 247.2 112 256C112 264.8 104.8 272 96 272H16C7.164 272 0 264.8 0 256zM496 240C504.8 240 512 247.2 512 256C512 264.8 504.8 272 496 272H408C399.2 272 392 264.8 392 256C392 247.2 399.2 240 408 240H496zM74.98 437C68.73 430.8 68.73 420.6 74.98 414.4L131.5 357.8C137.8 351.6 147.9 351.6 154.2 357.8C160.4 364.1 160.4 374.2 154.2 380.5L97.61 437C91.36 443.3 81.23 443.3 74.98 437V437zM414.4 74.98C420.6 68.73 430.8 68.73 437 74.98C443.3 81.23 443.3 91.36 437 97.61L374.8 159.8C368.5 166.1 358.4 166.1 352.2 159.8C345.9 153.6 345.9 143.5 352.2 137.2L414.4 74.98zM74.98 74.98C81.23 68.73 91.36 68.73 97.61 74.98L154.2 131.5C160.4 137.8 160.4 147.9 154.2 154.2C147.9 160.4 137.8 160.4 131.5 154.2L74.98 97.61C68.73 91.36 68.73 81.23 74.98 74.98V74.98zM437 414.4C443.3 420.6 443.3 430.8 437 437C430.8 443.3 420.6 443.3 414.4 437L352.2 374.8C345.9 368.5 345.9 358.4 352.2 352.2C358.4 345.9 368.5 345.9 374.8 352.2L437 414.4z" /></svg>
    }
    <span>{getTranslate('theme', lang)}</span>
  </S.Container>
}

export default SwitchTheme;

const S = {}
S.Container = styled.div`
  position: fixed;
  top: 20px;
  right: 20px;

  &:hover span {
    opacity: 1;
  }
  & svg {
    width: 30px;
    height: 30px;
    fill: ${({ theme }) => theme.text};
    cursor: pointer;
    z-index: 500;
  }
  & span {
    opacity: 0;
    position: absolute;
    color: ${({ theme }) => theme.text};
    border-radius: 5px;
    background: ${({ theme }) => theme.card};
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 3px 8px;
    font-size: 13px;
    top: calc(100% + 10px);
    right: 0;
    transition: .3s;
    font-weight: lighter;
    white-space: nowrap;
    z-index: 10;

    @media(max-width: 1100px) {
      display: none;
    }
  }
`